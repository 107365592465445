// import { Dropdown } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import styles from './MultiDropdownBootstrap.module.css'
import Dropdown from 'react-bootstrap/Dropdown';

function TerminalDropdownBootstrap({ onClickListener, selected, options}) {
    
    const [selectedOption, setSelectedOption] = useState(selected)
    // console.log('selected',selected)
    const onClick = (clickedItem) => {
        // console.log(terminal);
        setSelectedOption(clickedItem)
        onClickListener(clickedItem.terminal_id)
    }
    useEffect(() => {
        setSelectedOption(selected)
    }, [selected])

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle style={{
                    background: 'rgba(201, 188, 188, 0.5)',
                    color: 'black', border: '0px solid gray', textAlign: 'right',
                    fontSize:14, fontWeight: 500
                }} className={styles.toggle} id="dropdown-basic">
                    {
                        selectedOption.name
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        options.map((item, index) =>
                            <Dropdown.Item key={`termianldropdown+${index}`} onClick={(e) => { onClick(item) }} href="#">{item.name}</Dropdown.Item>
                        )
                    }

                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
export default TerminalDropdownBootstrap