import React, { useEffect, useState } from 'react'
import styles from './Proxy.module.css'
import TerminalDropdown from '../default/TerminalDropdown';
import TerminalDropdownBootstrap from '../default/MultiDropdownBootstrap';
import Button from '../default/Button'
import { AddonAPI, EmailProxy } from '../../api/DbAPI';
import { OrderSelect, Pagination, Sorting } from '../AccountEmail/AccountEmail';
import { dateFormat } from '../../utlis';

function Proxy() {
    let emailProxyAPI = new EmailProxy()
    const [refresh, setRefresh] = useState(false)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(3)
    const [page, setPage] = useState(0)
    const [totalRowCount, setTotalRowCount] = useState(10)
    const [orderBy, setOrderBy] = useState({ name: '', direction: '' })
    const [sortProxy, setSortProxy] = useState({ enabled: false, direction: 'up' })
    const [sortStartDate, setSortStartDate] = useState({ enabled: false, direction: 'up' })
    const [sortEndDate, setSortEndDate] = useState({ enabled: false, direction: 'up' })
    const [sortTerminal, setSortTerminal] = useState({ enabled: false, direction: 'up' })

    // get all the terminals from backend
    const [terminalList, setTerminalList] = useState([
        // { name: 'na', terminal_id: '' },
        { name: 'Terminal 1', terminal_id: 'asda1' },
        { name: 'Terminal 2', terminal_id: 'asda2' },
        { name: 'Terminal 3', terminal_id: 'asda3' }])

    // const [proxyInfo, setProxyInfo] = useState([
    //     { 'proxy': 'asdha-adask', "expire_date": "12-jun-22", 'terminal_id': 'asda', 'name': 'Terminal 1' },
    //     { 'proxy': 'asdka-adsd', "expire_date": "12-jun-22", 'terminal_id': 'asda1', 'name': 'Terminal 1' },
    //     { 'proxy': 'sdasd-adasd', "expire_date": "12-jun-22", 'terminal_id': 'asda2', 'name': 'Terminal 2' },
    //     { 'proxy': 'sdasd-asdad', "expire_date": "12-jun-22", 'terminal_id': '', 'name': 'Terminal 1' }
    // ])
    const [proxyInfo, setProxyInfo] = useState([])

    const [terminalChanged, setTerminalChanged] = useState({})
    useEffect(() => {
        (new AddonAPI()).getTerminalList((res) => {
            // console.log(res.data)
            setTerminalList(res.data)
            emailProxyAPI.getProxies((res) => {
                console.log('proxy', res.data)
                setTotalRowCount(res.data.total_row_count)
                setProxyInfo(res.data.data)
            }, (e) => {

            }, offset, limit, orderBy)
        }, (e) => {
            console.log(e)
        })

        // (new EmailProxy()).getProxies((res) => {

        // }, (e) => {

        // })
    }, [refresh])

    useEffect(() => {
        setRefresh(s => !s)
    }, [offset, limit])

    useEffect(() => {
        if (offset == 0) {
            setRefresh(s => !s)
        }
        setOffset(0)
    }, [orderBy])

    const getChanged = () => {
        let changedEmails = []
        let keys = Object.keys(terminalChanged)
        for (let k = 0; k < keys.length; k++) {
            changedEmails.push(terminalChanged[keys[k]])
        }
        return changedEmails
    }
    useEffect(() => {

    }, [terminalChanged])

    const onClickTerminalDropdown = (proxy) => {
        let key = proxy['proxy']
        let agg = {}
        agg[key] = proxy
        setTerminalChanged({ ...terminalChanged, ...agg })
    }
    const onClickSubmit = () => {
        let data = getChanged()
        console.log(data)
        emailProxyAPI.reassignProxyToTerminal((res) => {
            console.log(res.data)
        }, (e) => {

        }, data)
    }

    const onClickSortIcon = (name, direction) => {
        console.log(name, direction)
        setOrderBy({ name, direction })
        if (name === 'proxy' || name === 'start-date' || name === 'end-date') {
            setSortTerminal({...sortTerminal, enabled: false})
        } 
        if (name === 'proxy' || name === 'start-date' || name === 'terminal') {
            setSortEndDate({...sortEndDate, enabled: false})
        }
        if (name === 'proxy' || name === 'terminal' || name === 'end-date') {
            setSortStartDate({...sortStartDate, enabled: false})
        }
        if (name === 'terminal' || name === 'start-date' || name === 'end-date') {
            setSortProxy({...sortProxy, enabled: false})
        }
    }

    return (
        <div>
            {/* <OrderSelect orderBy={orderBy} setOrderBy={setOrderBy} orderList={['Start Date (ASC)', 'Start Date (DSC)', 'End Date (ASC)', 'End Date (DSC)']} /> */}
            <table className={styles.table} >
                <thead>
                    <tr className={styles.tr} >
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Proxy
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('proxy', direction)}
                                    setSortState={setSortProxy} sortState={sortProxy} />
                            </div>
                        </th>
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Start Date
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('start-date', direction)}
                                    setSortState={setSortStartDate} sortState={sortStartDate} />
                            </div>
                        </th>
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                End Date
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('end-date', direction)}
                                    setSortState={setSortEndDate} sortState={sortEndDate} />
                            </div>
                        </th>
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Terminal
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('terminal', direction)}
                                    setSortState={setSortTerminal} sortState={sortTerminal} />
                            </div>
                        </th>
                        {/* <th className={styles.th}>Country</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* <TerminalDropdown onChange={(e) => { console.log(e) }} /> */}
                    {
                        proxyInfo.map((proxyItem, index) => {
                            return (
                                <tr className={styles.tr} key={index}>
                                    <td className={`${styles.td}`}>{proxyItem.proxy}</td>
                                    <td className={`${styles.td}`}>{dateFormat(proxyItem.start_date)}</td>
                                    <td className={`${styles.td}`}>{dateFormat(proxyItem.end_date)}</td>
                                    <td className={`${styles.td} ${styles.td_terminal}`}>
                                        {/* {console.log(value)} */}
                                        <TerminalDropdownBootstrap
                                            options={terminalList}
                                            selected={proxyItem}
                                            onClickListener={terminal_id => onClickTerminalDropdown({ terminal_id, proxy: proxyItem.proxy })} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination page={page} setPage={setPage} limit={limit} setOffset={setOffset} setLimit={setLimit}
                totalRowCount={totalRowCount} />
            <Button text={'Submit'} onClick={onClickSubmit} />
        </div>
    )
}
export default Proxy