import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { validateUsername, validateEmail, validatePassword } from '../../utlis'
import { UserAPI } from '../../api/UserAPI.js'
import Button from '../default/Button'
import styles from './LoginRegister.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/monster-oldalra.png";

function Register() {
    const [regInfo, setRegInfo] = useState({
        'username': '', 'email': '', 'password': '', 'address': '',
        'number': '', 'zip': ''
    })
    const [registered, setRegistered] = useState(false)

    let navigate = useNavigate();
    const onUsernameChanged = (e) => {
        setRegInfo({ ...regInfo, 'username': e.target.value })
    }

    const onEmailChanged = (e) => {
        setRegInfo({ ...regInfo, 'email': e.target.value })
    }

    const onPasswordChanged = (e) => {
        setRegInfo({ ...regInfo, 'password': e.target.value })
    }

    const onAddressChanged = (e) => {
        setRegInfo({ ...regInfo, 'address': e.target.value })
    }

    
    const onNumberChanged = (e) => {
        setRegInfo({ ...regInfo, 'number': e.target.value })
    }

    const onZipChanged = (e) => {
        setRegInfo({ ...regInfo, 'zip': e.target.value })
    }

    const onResponse = (response) => {
        console.log(response)
        if (response.status === 200) {
            // setRegistered(true)
            toast.success("Register Successful")
            setTimeout(() => {
                navigate('/login')
            }, 1000)
        }
    }

    const onError = (error) => {
        console.log(error.response.data)
        alert(JSON.stringify(error.response.data))
    }

    const onSubmit = (e) => {
        if (validateUsername(regInfo.username) && validateEmail(regInfo.email) && validatePassword(regInfo.password)) {
            let userAPI = new UserAPI()
            userAPI.register(onResponse, onError, regInfo.username, regInfo.email, regInfo.password, 
                regInfo.address, regInfo.number, regInfo.zip)
        }
    }

    return (
        <div>

            <div className={styles.container}>
                <div className={styles.flex}>
                    <img src={logo} className={styles.logo}></img>
                    <br />
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.flex}>
                    {
                        registered
                            ? <div>
                                Successfully Registered!<br />
                                Redirecting to login page....
                            </div>
                            : <div className={styles.inputContainer}>
                                <InputElement type='text' name='Username' placeholder='Username' onChange={onUsernameChanged} className={styles.input} />
                                <InputElement type='email' name='Email' placeholder='Email' onChange={onEmailChanged} className={styles.input} />
                                <InputElement type='password' name='Password' placeholder='Password' onChange={onPasswordChanged} className={styles.input} />
                                <InputElement type='text' name='Address' placeholder='Address' onChange={onAddressChanged} className={styles.input} />
                                <InputElement type='text' name='Number' placeholder='Number' onChange={onNumberChanged} className={styles.input} />
                                <InputElement type='text' name='Zip' placeholder='Zip' onChange={onZipChanged} className={styles.input} />
                                <br />
                                <Button onClick={onSubmit} text={'Submit'} className={styles.submitbtn} />
                            </div>
                    }
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    )
}

function InputElement({ type, name, placeholder, onChange, className, style }) {
    return (
        <>
            <div>{name}</div>
            <input type={type} style={style} placeholder={placeholder} onChange={onChange} className={className}></input>
        </>
    )
}


export default Register
export { InputElement }