import React, { useEffect, useState } from 'react'
import styles from './DataView.module.css'
import UploadContent from './UploadContent'
import { proxyColumns, proxyData, setDataService } from './DataViewService'
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch, faTrash, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight,
    faRefresh
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ConfirmModal from '../default/ConfirmModal';
import { DeleteAPI } from '../../api/AdminAPI'
import EditEntryModal from './EditEntryModal';
import { TargetAPI } from '../../api/DbAPI';
// import Card from "@material-ui/core/Card";
var dataSaved = []
function DataView({ selectedPage, refresh }) {
    const [dataViewRefresh, setDataViewRefresh] = useState(false)
    // const [perPageRowCount, setPerPageRowCount] = useState(3)
    const [totalRowCount, setTotalRowCount] = useState(10)
    const [page, setPage] = useState(0)
    const [editModalShow, setEditModalShow] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState({})
    const [clearSelectedRows, setClearSelectedRows] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [title, setTitle] = useState('')
    const [query, setQuery] = useState({ column: '', string: '' })
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(3)
    const [orderBy, setOrderBy] = useState('username')

    useEffect(() => {
        setQuery({ column: '', string: '' })
        setColumns([])
        setData([])
        setPage(0)
        setOffset(0)
        setDataViewRefresh(s => !s)
        // setDataService(selectedPage, setTitle, setColumns, setData, setTotalRowCount, offset, limit, orderBy, { column: '', string: '' })
    }, [selectedPage, refresh])

    useEffect(() => {
        setDataService(selectedPage, setTitle, setColumns, setData, setTotalRowCount, offset, limit, orderBy, query)
        // setData([proxyData])
        // setColumns([proxyColumns])
        // setTitle('proxy')

    }, [dataViewRefresh, offset, limit])
    useEffect(() => {
        console.log('column', columns)
        let c = columns
        if (c[0] !== undefined && c[0].format === undefined) {
            console.log('here')
            // c[0].format = (row) => <button onClick={rowIdClicked.bind(row)}>{row.id}</button>
            // c[0].format = (row) => <IdButton row={row} selectedPage={selectedPage} setDataViewRefresh={setDataViewRefresh}/>
            setColumns(c)
        }
        // dataSaved = data
        // setData([])
        // setData(d)
    }, [columns])



    useEffect(() => {
        // console.log('refresing data', data)
        // if(data==[]){
        //     setData(dataSaved)
        // }
    }, [data])

    const rowIdClicked = (e) => {
        // onRowClicked(row)
        console.log(e.target.textContent)
        let id = e.target.textContent
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                onRowClicked(data[i])
                // console.log(data[i])
                break
            }
        }
    }
    // rowIdClicked.bind(this)

    const onRowClicked = (rowData) => {
        console.log(rowData)
        setSelectedRowData(rowData)
        setEditModalShow(true)
    }
    const onSelectedRowsChange = (allselect) => {
        console.log(allselect)
        setSelectedRows(allselect.selectedRows)
    }

    const onChangePage = (cpage) => {
        console.log(cpage)
        let totalPages = Math.ceil(totalRowCount / limit) - 1

        if (cpage >= 0 && cpage <= totalPages) {
            setPage(cpage)
            setOffset(cpage * limit)
        }
        if (cpage == -2) {
            // if(limit!=0)

            console.log(totalPages)
            setPage(totalPages)
            setOffset(totalPages * limit)
        }
    }


    return (

        <div className={styles.container}>
            <Search selectedPage={selectedPage} query={query} setQuery={setQuery} setDataViewRefresh={setDataViewRefresh} columns={columns} />
            <Delete selectedPage={selectedPage} selectedRows={selectedRows} setSelectedRows={setSelectedRows} setDataViewRefresh={setDataViewRefresh} setClearSelectedRows={setClearSelectedRows} />
            {/* <EditEntryModal selectedPage={selectedPage} data={selectedRowData} setData={setSelectedRowData} modalShow={editModalShow} setModalShow={setEditModalShow} setDataViewRefresh={setDataViewRefresh} /> */}
            <div className={styles.datatable}>
                <div className={styles.header} >
                    <div className={styles.headerTitle} >{title}</div>
                    <button className={styles.headerRefreshBtn} onClick={()=>{
                        setDataViewRefresh(s=>!s)
                    }}><FontAwesomeIcon icon={faRefresh} /></button>
                </div>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    // onRowClicked={onRowClicked}
                    onSelectedRowsChange={onSelectedRowsChange}
                    clearSelectedRows={clearSelectedRows}
                    defaultSortFieldId={1}
                    paginationPerPage={3}
                    noHeader={true}
                    // noTableHead= {true}
                    // subHeader={({})=><button>hello</button>}
                    // sortIcon={<SortIcon />}
                    // pagination
                    onChangePage={onChangePage}
                    // paginationTotalRows={totalRowCount}
                    selectableRows
                />
            </div>
            <div className={styles.pagination} >
                <div style={{ display: 'inline', color: 'rgba(83, 74, 74, 0.493)' }}>
                    Show Per Page
                    <select name="per-page" value={limit} className={styles.perPageSelect} onChange={(e) => {
                        setOffset(0)
                        setLimit(parseInt(e.target.value))
                    }}>
                        <option value="3">3</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="125">125</option>
                        <option value="150">150</option>
                        <option value="175">175</option>
                        <option value="200">200</option>
                        <option value="250">250</option>
                        <option value="300">300</option>
                    </select>
                </div>
                <div style={{ display: 'inline', color: 'rgba(83, 74, 74, 0.493)' }}>{page * limit + 1}-{page * limit + limit < totalRowCount ? page * limit + limit : totalRowCount} of {totalRowCount}</div>
                <div style={{ display: 'inline' }}>
                    <button className={styles.paginationAngleIcon} onClick={() => onChangePage(0)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button>
                    <button className={styles.paginationAngleIcon} onClick={() => onChangePage(page - 1)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                    <button className={styles.paginationAngleIcon} onClick={() => onChangePage(page + 1)}><FontAwesomeIcon icon={faAngleRight} /></button>
                    <button className={styles.paginationAngleIcon} onClick={() => onChangePage(-2)}><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                </div>

            </div>
        </div>
    )
}

export function IdButton({ row, selectedPage, setDataViewRefresh }) {
    const [editModalShow, setEditModalShow] = useState(false)
    useEffect(() => {
        //    console.log('id button') 
    }, [])
    return (
        <>
            <EditEntryModal selectedPage={selectedPage} row={row} modalShow={editModalShow} setModalShow={setEditModalShow} setDataViewRefresh={setDataViewRefresh} />
            <a href='#' onClick={() => setEditModalShow(true)}>{row.id}</a>
        </>

    )
}

function Delete({ selectedPage, selectedRows, setSelectedRows, setDataViewRefresh, setClearSelectedRows }) {
    let deleteAPI = new DeleteAPI()
    const [modalShow, setModalShow] = useState(false)
    const onResponse = (res) => {
        console.log(res.data)
        setSelectedRows([])
        setClearSelectedRows(s => !s)
        setDataViewRefresh(s => !s)
    }

    const onError = (e) => {
        console.log(e.response)
        alert(JSON.stringify(e.response.data))
    }

    const handleConfirm = () => {
        console.log('deleting')
        let ids = []
        for (let i = 0; i < selectedRows.length; i++) {
            ids.push(selectedRows[i].id)
        }
        if (selectedPage === 'user') {
            deleteAPI.deleteUser(onResponse, onError, ids)
        } else if (selectedPage === 'group-email') {
            deleteAPI.deleteEmail(onResponse, onError, ids)
        } else if (selectedPage === 'proxy') {
            deleteAPI.deleteProxy(onResponse, onError, ids)
        } else if (selectedPage === 'terminal') {
            deleteAPI.deleteTerminal(onResponse, onError, ids)
        } else if (selectedPage === 'user-target') {
            let targetAPI = new TargetAPI()
            targetAPI.deleteTarget(onResponse, onError, ids)
        }

    }

    const onClickDelete = () => {
        console.log('clicked')
        setModalShow(true)
    }
    return (
        <div>
            {
                selectedRows.length > 0
                    ? <>
                        <button className={styles.deleteBtn} onClick={onClickDelete}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                        <ConfirmModal title={'Delete Confirmation'} msg={'Are You Sure?'} modalShow={modalShow} setModalShow={setModalShow} handleConfirm={handleConfirm} />
                    </>
                    : null
            }

        </div>
    )
}

function Search({ query, setQuery, setDataViewRefresh, columns, selectedPage }) {
    const [inputValue, setInputValue] = useState('')
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        setInputValue('')
        setSelected(0)
    }, [selectedPage])

    const onClickDropdown = (index) => {
        if (columns[selected] !== undefined) {
            setSelected(index)
        }
    }


    const onClickSearch = () => {
        setQuery({ column: columns[selected].dbColName, string: inputValue })
        setDataViewRefresh(s => !s)
    }

    return (
        <div>
            <div className={styles.dropdownContainer} >
                <Dropdown style={{ display: 'inline' }}>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic" className={styles.dropdown}>
                        {
                            columns[selected]
                                ? columns[selected].name
                                : null
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            columns.map((value, index) => {
                                return (
                                    <div key={'columns' + index}>
                                        <Dropdown.Item onClick={(e) => onClickDropdown(index)}>{value.name}</Dropdown.Item>
                                    </div>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <input className={styles.searchInput} placeholder={'search...'} value={inputValue} onChange={(e) => setInputValue(e.target.value)}></input>
                <button className={styles.searchBtn} onClick={onClickSearch}><FontAwesomeIcon icon={faSearch} /> Search</button>
            </div>
        </div>
    )
}
export default DataView