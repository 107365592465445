import React, { useEffect, useState, useRef } from 'react'
import styles from './TerminalDropdown.module.css'
import Dropdown from 'react-dropdown';

function TerminalDropdown({ onChange, controlClassName, menuClassName }) {
    const ref = useRef()
    const [clickedOutside, setClickedOutside] = useState(false)
    const [terminalOptions, setTerminalOptions] = useState([
        { value: 0, label: 'Terminal 1' },
        { value: 1, label: 'Terminal 2' },
        { value: 2, label: 'Terminal 3' }])
    const [terminalId, setTerminalId] = useState(['asda', 'asda1', 'asda2'])
    const defaultOption = terminalOptions[0]
    const onChangeDropdown = (e) => {
        let id = terminalId[e.value]
        onChange({ ...e, id })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setClickedOutside(true)
                console.log('outside')
            }
        }
        function handleClickInside(event) {
            if (ref.current) {
                setClickedOutside(false)
                console.log('inside')
            }
        }
        ref.current.addEventListener('mousedown', handleClickInside)
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <div ref={ref} >
            {
                clickedOutside
                    ? <Dropdown
                        disabled
                        options={terminalOptions}
                        controlClassName={controlClassName}
                        menuClassName={menuClassName}
                        className={styles.className}
                        placeholderClassName={styles.placeholderClassName}
                        onChange={onChangeDropdown} value={defaultOption} placeholder="Select an option" />
                    : <Dropdown
                        options={terminalOptions}
                        controlClassName={controlClassName}
                        menuClassName={menuClassName}
                        className={styles.className}
                        placeholderClassName={styles.placeholderClassName}
                        onChange={onChangeDropdown} value={defaultOption} placeholder="Select an option" />
            }
            {/* <Dropdown
                options={terminalOptions}
                controlClassName={controlClassName}
                menuClassName={menuClassName}
                className={styles.className}
                placeholderClassName={styles.placeholderClassName}
                onChange={onChangeDropdown} value={defaultOption} placeholder="Select an option" /> */}
        </div>
    )
}
export default TerminalDropdown