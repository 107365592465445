import axios from 'axios'
import { backend_host } from '../config'

class UserAPI {
    
    register(onResponse, onError, username, email, password, address, number, zip) {
        axios.post(backend_host + 'user/register/', {
            username: username,
            email: email,
            password: password,
            address: address,
            number: number,
            zip: zip
        }).then(onResponse).catch(onError);
    }

    login(onResponse, onError, email, password) {
        axios.post(backend_host + 'user/login/', {
            email: email,
            password: password
        }).then(onResponse).catch(onError)
    }

    logout(onResponse, onError) {
        axios.get(backend_host + 'user/logout/')
            .then(onResponse).catch(onError)
    }

    register_fb(onResponse, onError, accessToken) {
        axios.post(backend_host + 'user/register-fb/', {
            access_token: accessToken
        }).then(onResponse).catch(onError);
    }

    register_google(onResponse, onError, credential) {
        axios.post(backend_host + 'user/register-google/', {
            credential: credential
        }).then(onResponse).catch(onError);
    }

    terminate_account(onResponse, onError) {
        axios.get(backend_host + 'user/terminate-account/')
            .then(onResponse).catch(onError);
    }

    change_password(onResponse, onError, old_password, new_password) {
        axios.post(backend_host + 'user/change-password/', {
            old_password: old_password,
            new_password: new_password
        }).then(onResponse).catch(onError);
    }
}

export { UserAPI }