import React, { useState, useEffect } from 'react'
import HeaderMain from '../header-and-navbar/HeaderMain'
import styles from './AddOnEmailAccount.module.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { SingleSelect } from '../default/Select';
import Button from '../default/Button';
import { Inquiry } from '../default/Help';
import logo from '../../assets/monster-oldalra.png'
import Slider from '../default/Slider';
import { AddonAPI } from '../../api/DbAPI';

function AddOnEmailAccount() {
  let api = new AddonAPI()
  const [terminalList, setTerminalList] = useState([
    { "name": "Terminal 1", "terminal_id": "asdab-asdu" },
    { "name": "Terminal 2", "terminal_id": "asdab-asdu2" },
    { "name": "Terminal 3", "terminal_id": "asdab-asdu3" }
  ])

  // const [terminalOptions, setTerminalOptions] = useState([{ value: 0, label: 'Terminal 1' }, { value: 1, label: 'Terminal 2' }, { value: 2, label: 'Terminal 3' }])
  // let terminalOptions = []
  const [terminalOptions, setTerminalOptions] = useState([])
  const [selectedTerminal, setSelectedTerminal] = useState()
  const [emailQuota, setEmailQuota] = useState(['$20 for 100 accounts', '$5 for 10 accounts', '$2 for 3 accounts'])
  const [selectedQuota, setSelectedQuota] = useState(0)
  const [sliderValue, setSliderValue] = useState(0)
  const [emailPrice, setEmailPrice] = useState(null)

  const defaultOption = terminalOptions[0]

  const [totalEmails, setTotalEmails] = useState(10)
  const [usedEmails, setUsedEmails] = useState(5)


  useEffect(() => {
    api.getTerminalList((res) => {
      console.log(res.data)
      setTerminalList(res.data)
    }, (e) => {
      console.log(e)
    })
  }, [])
  
  useEffect(() => {
    let terminalOptionsTemp = []
    terminalList.map((value, index) => {
      terminalOptionsTemp.push({ "value": index, "label": value.name })
    })
    setTerminalOptions(terminalOptionsTemp)
    setSelectedTerminal(0)
     console.log(terminalOptions)
  }, [terminalList])

  useEffect(() => {
    setEmailPrice(sliderValue * 10)
  }, [sliderValue])

  const onChangeTerminalDropdown = (e) => {
    console.log(e)
    setSelectedTerminal(e.value)
  }


  const onClickSubmit = (e) => {
    let terminal = terminalList[selectedTerminal]
    console.log('terminal id:', terminal.terminal_id, 'terminal name:', terminal.name, 'emails:', sliderValue)
    // api.buyTerminal(()=>{

    // }, ()=>{

    // })
  }

  return (
    <div>
      {/* <HeaderMain/> */}
      <div style={{ textAlign: 'center' }}>
        <img src={logo} className={styles.logo}></img>
      </div>

      <div className={styles.container}>
        <div className={styles.title} style={{ marginTop: 0 }}>
          Select Terminal
          <Inquiry title='To what terminal do you want to attach the email accounts' />
        </div>

        <Dropdown options={terminalOptions} onChange={onChangeTerminalDropdown} value={defaultOption} placeholder="Select an option" />
        <div className={styles.info}>You have total {totalEmails} emails. You have used {usedEmails} emails. Remaining
          emails {totalEmails - usedEmails}</div>

        <div className={styles.title}> Select Email Quota</div>
        <Slider sliderValue={sliderValue} setSliderValue={setSliderValue} min={0} max={100} />
        <div className={styles.price}>Total emails: {sliderValue}</div>
        <div className={styles.price}>Price: ${emailPrice}</div>
        {/* <div>
          <SingleSelect items={emailQuota} onChange={(e) => {
            console.log(e.target.value)
            setSelectedQuota(e.target.value)
          }}
            selected={selectedQuota} />
        </div> */}
        <Button onClick={onClickSubmit} text={'Submit'} />
      </div>
    </div>

  )
}



export default AddOnEmailAccount