import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './Sidebar.module.css'

function Sidebar({ onClickCallback }) {
    const location = useLocation()
    let navigate = useNavigate()
    const [selected, setSelected] = useState('dashboard')

    useEffect(() => {
        // console.log(location.pathname)
        if (location.pathname === '/') {
            navigate('/dashboard')
            setSelected('dashboard')
            onClickCallback('dashboard')
            // window.location.reload()
        } else {
            let path = location.pathname
            // console.log(path);
            if (path[0] == '/') {
                path = path.substring(1, path.length)
            }
            setSelected(path)
            onClickCallback(path)
            // console.log(path)
        }

    }, [location])


    const OnClick = (text) => {
        navigate('/' + text.toLowerCase())
        setSelected(text.toLowerCase())
        onClickCallback(text.toLowerCase())
    }

    return (
        <div>
            <SidebarButton text={'Dashboard'} label={'dashboard'} selected={selected} onClick={() => { OnClick('dashboard') }} />
            <SidebarButton text={'Account'} label={'account'} selected={selected} onClick={() => { OnClick('account') }} />
            <SidebarButton text={'Add-ons'} label={'add-ons'} selected={selected} onClick={() => { OnClick('add-ons') }} />
            <SidebarButton text={'Support'} label={'support'} selected={selected} onClick={() => { OnClick('support') }} />
            <SidebarButton text={'Account Email'} label={'account-email'} selected={selected} onClick={() => { OnClick('account-email') }} />
            <SidebarButton text={'Proxy'} label={'proxy'} selected={selected} onClick={() => { OnClick('proxy') }} />
            <SidebarButton text={'Target'} label={'target'} selected={selected} onClick={() => { OnClick('target') }} />
        </div>
    )
}

function SidebarButton({ text, label, selected, onClick }) {
    let backgroundColor = 'rgb(236, 233, 233)'
    // let additionText = '' 
    let textDecoration = ''
    if (selected === label.toLowerCase()) {
        // backgroundColor = 'white'
        // additionText = ' >'
        textDecoration = 'underline'
    }

    return (
        <>
            <button style={{
                border: '1px',
                backgroundColor: backgroundColor,
                // marginTop: 5,
                // padding: '10px 5px',
                minWidth: '100%',
                color: 'white',
                // boxShadow: '0 1px 1px gray',
                textAlign: 'left',
                textDecoration: textDecoration,
                textShadow: '0 0 4px black',
                fontSize: 20
            }} onClick={onClick}>{text}</button>
        </>
    )
}


export default Sidebar