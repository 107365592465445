import React, { useEffect, useState } from 'react'
import styles from './AccountEmail.module.css'
import TerminalDropdown from '../default/TerminalDropdown';
import TerminalDropdownBootstrap from '../default/MultiDropdownBootstrap';
import Button from '../default/Button'
import { AddonAPI, EmailProxy } from '../../api/DbAPI';
import AuthCheck from '../register-and-login/AuthCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch, faTrash, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight,
    faRefresh, faSortUp, faSortDown
} from '@fortawesome/free-solid-svg-icons'
import { dateFormat } from '../../utlis';


function AccountEmail() {
    let emailProxyAPI = new EmailProxy()
    const [refresh, setRefresh] = useState(false)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(3)
    const [page, setPage] = useState(0)
    const [totalRowCount, setTotalRowCount] = useState(10)
    const [orderBy, setOrderBy] = useState({name:'',direction:''})
    const [sortEmail, setSortEmail] = useState({ enabled: false, direction: 'up' })
    const [sortDateAdded, setSortDateAdded] = useState({ enabled: false, direction: 'up' })
    const [sortTerminal, setSortTerminal] = useState({ enabled: false, direction: 'up' })
    // get all the terminals from backend
    const [terminalList, setTerminalList] = useState([
        { "name": "Terminal 1", "terminal_id": "asdab-asdu" },
        { "name": "Terminal 2", "terminal_id": "asdab-asdu2" },
        { "name": "Terminal 3", "terminal_id": "asdab-asdu3" }
    ])
    // const [terminalList, setterminalList] = useState([
    //     { name: 'na', id: '' },
    //     { name: 'Terminal 1', id: 'asda1' },
    //     { name: 'Terminal 2', id: 'asda2' },
    //     { name: 'Terminal 3', id: 'asda3' }])

    // const [terminalInfo, setTerminalInfo] = useState([
    //     { 'email': 'ragib@gmail.com', 'terminal_id': 'asda', 'name': 'Terminal 1' },
    //     { 'email': 'ohid@gmail.com', 'terminal_id': 'asda1', 'name': 'Terminal 1' },
    //     { 'email': 'imran@gmail.com', 'terminal_id': 'asda2', 'name': 'Terminal 2' },
    //     { 'email': 'andrew@gmail.com', 'terminal_id': '', 'name': 'na' }
    // ])
    const [terminalInfo, setTerminalInfo] = useState([])

    const [terminalChanged, setTerminalChanged] = useState({})
    // const fetchData = async () => { 
    //     let addonApi = new AddonAPI()
    //     let netcall = await addonApi.getTerminalList().catch(()=>{
    //         console.log('error')
    //     })
    //     console.log(netcall)
    //  }
    useEffect(() => {
        // fetchData()
        (new AddonAPI()).getTerminalList((res) => {
            // console.log(res.data)
            setTerminalList(res.data)
            emailProxyAPI.getEmails((res) => {
                console.log('emails', res.data)
                setTotalRowCount(res.data.total_row_count)
                setTerminalInfo(res.data.data)
            }, (e) => {

            }, offset, limit, orderBy)
        }, (e) => {
            console.log(e)
        })
        //  console.log(terminalOptions)
    }, [refresh])

    useEffect(() => {
        setRefresh(s => !s)
    }, [offset, limit])

    useEffect(() => {
        if (offset == 0) {
            setRefresh(s => !s)
        }
        setOffset(0)
    }, [orderBy])

    const getChanged = () => {
        let changedEmails = []
        let keys = Object.keys(terminalChanged)
        for (let k = 0; k < keys.length; k++) {
            changedEmails.push(terminalChanged[keys[k]])
        }
        return changedEmails
    }
    useEffect(() => {
        // console.log(getChanged())
    }, [terminalChanged])

    const onClickTerminalDropdown = (terminal) => {
        let email = terminal['email']
        let agg = {}
        agg[email] = terminal
        setTerminalChanged({ ...terminalChanged, ...agg })
    }
    const onClickSubmit = () => {
        let data = getChanged()
        console.log(data)
        emailProxyAPI.reassignEmailToTerminal((res) => {
            console.log(res.data)
        }, (e) => {

        }, data)
    }

    const onClickSortIcon = (name, direction) => {
        // console.log(name, direction)
        setOrderBy({name, direction})
        if (name === 'email' || name === 'date-added') {
            setSortTerminal({...sortTerminal, enabled: false})
        } 
        if (name === 'terminal' || name === 'date-added') {
            setSortEmail({...sortEmail, enabled: false})
        } 
        if (name === 'email' || name === 'terminal') {
            setSortDateAdded({...sortDateAdded, enabled: false})
        }
    }

    return (
        <div>
            <AuthCheck />
            {/* <OrderSelect orderBy={orderBy} setOrderBy={setOrderBy} orderList={['Date Added (ASC)', 'Date Added (DSC)']} /> */}
            <table className={styles.table} >
                <thead>
                    <tr className={styles.tr} >
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Email
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('email',direction)} setSortState={setSortEmail} sortState={sortEmail} />
                            </div>
                        </th>
                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Date Added
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('date-added',direction)} setSortState={setSortDateAdded} sortState={sortDateAdded} />
                            </div>
                        </th>

                        <th className={styles.th}>
                            <div className={styles.thElem} >
                                Terminal
                                <Sorting onClickedIcon={(direction) => onClickSortIcon('terminal',direction)} setSortState={setSortTerminal} sortState={sortTerminal} />
                            </div>
                        </th>
                        {/* <th className={styles.th}>Country</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* <TerminalDropdown onChange={(e) => { console.log(e) }} /> */}
                    {
                        terminalInfo.map((terminal, index) => {
                            return (
                                <tr className={styles.tr} key={index}>
                                    <td className={`${styles.td}`}>{terminal.email}</td>
                                    <td className={`${styles.td}`}>{dateFormat(terminal.date_added)}</td>
                                    <td className={`${styles.td} ${styles.td_terminal}`}>
                                        {/* {console.log(value)} */}
                                        <TerminalDropdownBootstrap
                                            options={terminalList}
                                            selected={terminal}
                                            onClickListener={terminal_id => onClickTerminalDropdown({ terminal_id, email: terminal.email })} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination page={page} setPage={setPage} limit={limit} setOffset={setOffset} setLimit={setLimit}
                totalRowCount={totalRowCount} />
            <Button text={'Submit'} onClick={onClickSubmit} />
        </div>
    )
}

export function Pagination({ page, limit, setPage, setOffset, setLimit, totalRowCount }) {

    const onChangePage = (cpage) => {
        let totalPages = Math.ceil(totalRowCount / limit) - 1

        if (cpage >= 0 && cpage <= totalPages) {
            setPage(cpage)
            setOffset(cpage * limit)
        }
        if (cpage == -2) {
            // if(limit!=0)

            console.log(totalPages)
            setPage(totalPages)
            setOffset(totalPages * limit)
        }
    }
    return (
        <div className={styles.pagination} >

            <div style={{ display: 'inline', color: 'rgba(83, 74, 74, 0.493)' }}>
                Show Per Page
                <select name="per-page" value={limit} className={styles.perPageSelect} onChange={(e) => {
                    setOffset(0)
                    setLimit(parseInt(e.target.value))
                }}>
                    <option value="3">3</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="125">125</option>
                    <option value="150">150</option>
                    <option value="175">175</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                </select>
            </div>
            <div style={{ display: 'inline', color: 'rgba(83, 74, 74, 0.493)' }}>{page * limit + 1}-{page * limit + limit < totalRowCount ? page * limit + limit : totalRowCount} of {totalRowCount}</div>
            <div style={{ display: 'inline' }}>
                <button className={styles.paginationAngleIcon} onClick={() => onChangePage(0)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button>
                <button className={styles.paginationAngleIcon} onClick={() => onChangePage(page - 1)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button className={styles.paginationAngleIcon} onClick={() => onChangePage(page + 1)}><FontAwesomeIcon icon={faAngleRight} /></button>
                <button className={styles.paginationAngleIcon} onClick={() => onChangePage(-2)}><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
            </div>

        </div>

    )
}

export function OrderSelect({ orderBy, setOrderBy, orderList }) {

    return (
        <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <div style={{ padding: '5px 10px', background: 'rgba(128, 128, 128, 0.2)' }}>Order By</div>
            <select name="sort-by" value={orderBy} className={styles.orderBy} onChange={(e) => {
                setOrderBy(e.target.value)
            }}>
                <option value="">None</option>
                {
                    orderList.map((value, index) => {
                        return (
                            <option key={'orderList' + index} value={value}>{value}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export function Sorting({ sortState, setSortState, onClickedIcon }) {
    const onClick = (direction) => {
        if (direction === 'down') {
            setSortState({ ...sortState, direction: 'down' })
        }
        else if (direction === 'up') {
            setSortState({ ...sortState, direction: 'up' })
        }
        onClickedIcon(direction)
    }
    return (
        <div>
            {
                sortState.enabled == false ?
                    <FontAwesomeIcon icon={faSortUp} onClick={() => {
                        setSortState({ ...sortState, enabled: true })
                        onClickedIcon(sortState.direction)
                    }} className={styles.sortIconDisabled} />
                    : <>{
                        sortState.direction == 'up'
                            ? <FontAwesomeIcon onClick={() => onClick('down')} icon={faSortUp} className={styles.sortIcon} />
                            : <FontAwesomeIcon onClick={() => onClick('up')} icon={faSortDown} className={styles.sortIcon} />
                    }
                    </>
            }

        </div>
    )
}
export default AccountEmail