import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styles from './TerminalInfo.module.css'
import { DashboardAPI } from '../../api/DbAPI'
import HeaderMain from '../header-and-navbar/HeaderMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../default/Button'
import { Helmet } from "react-helmet";
import AuthCheck from '../register-and-login/AuthCheck'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function TerminalInfo() {
    let api = new DashboardAPI()
    let { id } = useParams();
    const navigate = useNavigate()
    // const [info, setInfo] = useState({ ram: '', ssd_storage_size: '', core_count: '', group_email_quota: '' })
    const [info, setInfo] = useState({
        "id": "",
        "password": "",
        "name": "",
        "start_date": "",
        "end_date": "",
        "ip": "",
        "port": "",
        "ram": "",
        "core_count": 0,
        "ssd_storage_size": "",
        "is_active": "",
        "username": "",
        "group_email_quota": 0,
        "assigned_emails": [],
        "assigned_proxies": 0
    })


    useEffect(() => {
        console.log(id);
        api.terminal(res => {
            console.log(res.data)
            setInfo(res.data)
        }, e => {
            alert(JSON.stringify(e.response.data))
            setInfo({})
        }, id)
    }, [])


    const handleProxy = (mode) => {
        navigate('/proxy')
    }

    const handleEmail = (mode) => {
        navigate('/account-email')
    }

    const onClickTerminalNameSave = (e) => {
        api.terminalNameSave((res) => {
            console.log(res.data)
            toast.success('Name Saved')
        }, (e) => {
            console.log(e.response)
            alert(JSON.stringify(e.response.data))
        }, info.id, info.name)
    }

    return (
        <div>
            <Helmet>
                <title>Terminal | GMonster</title>
            </Helmet>

            {/* <HeaderMain /> */}
            {/* <div style={{ display: 'flex', justifyContent:'center' }}> */}
            <div className={styles.infoContainer} >
            {
                info.id ?

                    <div className={styles.container}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input className={styles.terminalNameInput} value={info.name} onChange={(e) => {
                                setInfo({ ...info, name: e.target.value })
                            }}></input>
                            <button className={styles.terminalSaveBtn} onClick={onClickTerminalNameSave}>Save</button>
                        </div>
                        <div className={styles.itemPanel} ><div className={styles.subtitle}>Start date</div>: {info.start_date}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>End date</div>: {info.end_date}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>Core Count</div>: {info.core_count}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>SSD Storage Size</div>: {info.ssd_storage_size}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>Ram</div>: {info.ram}</div>
                        {/* <div><div className={styles.subtitle}>id:</div> {info.id}</div> */}
                        <div className={styles.itemPanel}><div className={styles.subtitle}>Username</div>: {info.username}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>IP</div>: {info.ip}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>Port</div>: {info.port}</div>
                        <div className={styles.itemPanel}><div className={styles.subtitle}>Password</div>: {info.password}</div>

                        <div className={styles.divider}></div>

                        <div className={styles.itemPanel}><div className={styles.subtitle}>Assigned Proxies:</div> {info.assigned_proxies.length}
                            {/* <FontAwesomeIcon className={styles.fontawesomebutton} icon={faPlus} onClick={e => handleProxy('add')}></FontAwesomeIcon>
                    <FontAwesomeIcon className={styles.fontawesomebutton} icon={faMinus} onClick={e => handleProxy('remove')}></FontAwesomeIcon> */}
                        </div>
                        {/* <div>
                    <div className={styles.subtitle}>Free Proxies: </div>
                    {info.free_proxies}
                </div> */}
                        <Button text={'Configure Proxies'} onClick={handleProxy} style={{ padding: 10 }}></Button>

                        <div className={styles.divider}></div>

                        <div className={styles.itemPanel}><div className={styles.subtitle}>Group Email Quota:</div> {info.group_email_quota}</div>
                        <div className={styles.itemPanel}>
                            <div className={styles.subtitle}>Emails</div>
                            <div>Available Emails: {info.assigned_emails.length}</div>
                            {/* <div className={styles.divider} style={{ marginTop: 0, marginBottom: 0 }}></div> */}
                            <div style={{ padding: '10px 10px' }}>
                                <div style={{ border: '1px solid rgb(218, 213, 213)', padding: '10px 10px', borderRadius: 4 }}>
                                    {info.assigned_emails.map((value, index) => {
                                        return <div key={`emails${index}`}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    {value}
                                                </div>
                                                {/* <button style={{ padding: 0, border: '0px solid gray' }} onClick={e => onClickRemoveEmail(index)}>
                                            <FontAwesomeIcon
                                                // className={styles.fontawesomebutton}
                                                icon={faTimes}
                                                style={{ color: 'red' }}></FontAwesomeIcon>
                                        </button> */}
                                            </div>
                                            <div className={styles.divider} style={{ marginTop: 3, marginBottom: 3 }}></div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <Button text={'Configure Email'} onClick={handleEmail} style={{ padding: 10 }}></Button>

                        </div>

                    </div>
                    : null
            }
            </div>

            {/* </div> */}
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default TerminalInfo