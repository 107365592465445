import React, { useState, useEffect } from 'react'
import HeaderMain from '../header-and-navbar/HeaderMain'
import styles from './AddOnProxy.module.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { SingleSelect } from '../default/Select';
import Button from '../default/Button';
import { Inquiry } from '../default/Help';
import { InputElement } from '../register-and-login/Register'
import logo from '../../assets/monster-oldalra.png'
import Slider from '../default/Slider'
import { AddonAPI } from '../../api/DbAPI';

function AddOnProxy() {
  let api = new AddonAPI()
  const [terminalList, setTerminalList] = useState([
    { "name": "Terminal 1", "terminal_id": "asdab-asdu" },
    { "name": "Terminal 2", "terminal_id": "asdab-asdu2" },
    { "name": "Terminal 3", "terminal_id": "asdab-asdu3" }
  ])

  // const [terminalOptions, setTerminalOptions] = useState([{value:0, label:'Terminal 1'}, {value:1, label:'Terminal 2'},{value:2, label:'Terminal 3'}])
  const [terminalOptions, setTerminalOptions] = useState([])
  const [selectedTerminal, setSelectedTerminal] = useState()
  const defaultOption = terminalOptions[0]

  const [totalProxies, setTotalProxies] = useState(30)
  const [usedProxies, setUsedProxies] = useState(20)
  const [sliderValue, setSliderValue] = useState(0)
  const [proxyPrice, setProxyPrice] = useState(null)

  useEffect(() => {
    api.getTerminalList((res) => {
      console.log(res.data)
      setTerminalList(res.data)
    }, (e) => {
      console.log(e)
    })


  }, [])

  useEffect(() => {
    let terminalOptionsTemp = []
    terminalList.map((value, index) => {
      terminalOptionsTemp.push({ "value": index, "label": value.name })
    })
    setTerminalOptions(terminalOptionsTemp)
    setSelectedTerminal(0)
    //  console.log(terminalOptions)
  }, [terminalList])

  useEffect(() => {
    setProxyPrice(sliderValue * 10)
  }, [sliderValue])

  const onClickSubmit = (e) => {
    let terminal = terminalList[selectedTerminal]
    console.log('terminal id:', terminal.terminal_id, 'terminal name:', terminal.name, 'proxy:', sliderValue)
    // api.buyTerminal(()=>{

    // }, ()=>{

    // })
  }

  return (
    <div>
      {/* <HeaderMain/> */}
      <div style={{ textAlign: 'center' }}>
        <img src={logo} className={styles.logo}></img>
      </div>

      <div className={styles.container}>
        <div className={styles.title} style={{ marginTop: 0 }}>
          Select Terminal
          <Inquiry title='To what terminal do you want to attach the email accounts' />
        </div>

        <Dropdown options={terminalOptions} onChange={(e) => { console.log(e) }} value={defaultOption} placeholder="Select an option" />
        <div className={styles.info}>You have total {totalProxies} proxies. You have used {usedProxies} proxies. Remaining
          proxies {totalProxies - usedProxies}</div>
        <div className={styles.title}> How Many Proxies Do You Want?</div>
        {/* <InputElement type={'number'} placeholder={'enter proxy count...'} style={{paddingLeft:5}}/> */}
        <br />
        {/* <div>
          <SingleSelect items={emailQuota} onChange={(e) => {
            console.log(e.target.value)
            setSelectedQuota(e.target.value)
          }}
            selected={selectedQuota} />
        </div> */}
        <Slider min={0} max={300} sliderValue={sliderValue} setSliderValue={setSliderValue} />
        <div className={styles.price}>Total Proxies: {sliderValue}</div>
        <div className={styles.price}>Price: ${proxyPrice}</div>
        <Button onClick={onClickSubmit} text={'Submit'} />
      </div>
    </div>
  )
}

export default AddOnProxy