import { userData, emailData, terminalData, proxyData } from "./DataViewDummy"
import { FetchAPI } from "../../api/AdminAPI"
import { TargetAPI } from "../../api/DbAPI"
import { IdButton } from "./DataView"
import { dateFormat } from "../../utlis"

export const userColumns = [
  {
    id: 1,
    name: "Id",
    dbColName: "id",
    selector: (row) => row.id,
    format: (row) => <IdButton row={row} selectedPage={'user'}/>,
    sortable: true,
    reorder: true,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  },
  {
    id: 2,
    name: "Username",
    dbColName: "username",
    selector: (row) => row.username,
    sortable: true,
    center: true,
    reorder: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  },  {
    id: 3,
    name: "Email",
    dbColName: "email",
    selector: (row) => row.email,
    sortable: true,
    center: true,
    reorder: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  },
  {
    id: 4,
    name: "Number",
    dbColName: "number",
    selector: (row) => row.number,
    center: true,
    sortable: true,
    reorder: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  },
  {
    id: 5,
    name: "Address",
    dbColName: "address",
    selector: (row) => row.address,
    center: true,
    sortable: true,
    right: true,
    reorder: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  },
  {
    id: 6,
    name: "Zip",
    dbColName: "zip",
    selector: (row) => row.zip,
    center: true,
    sortable: true,
    right: true,
    reorder: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'}
  }
]

export const emailColumns = [
  {
    id: 1,
    name: "Id",
    dbColName: "id",
    selector: (row) => row.id,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    format: (row) => <IdButton row={row} selectedPage={'group-email'}/>,
    sortable: true,
    reorder: true
  },
  {
    id: 2,
    name: "First Name",
    dbColName: "first_name",
    selector: (row) => row.first_name,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "Last Name",
    dbColName: "last_name",
    selector: (row) => row.last_name,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    sortable: true,
    reorder: true
  },
  {
    id: 4,
    name: "Email",
    dbColName: "email",
    selector: (row) => row.email,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 5,
    name: "Email Password",
    dbColName: "password",
    selector: (row) => row.password,
    center: true,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 6,
    name: "Assigned",
    dbColName: "is_assigned",
    selector: (row) => row.is_assigned.toString(),
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 7,
    name: "Upload Date",
    dbColName: "upload_date",
    selector: (row) =>  row.upload_date!=null? dateFormat(row.upload_date,'datetime'): '',
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 8,
    name: "User",
    dbColName: "user",
    selector: (row) =>  row.user,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }
  
]

export const proxyColumns = [
  {
    id: 1,
    name: "Id",
    dbColName: "id",
    selector: (row) => row.id,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    format: (row) => <IdButton row={row} selectedPage={'proxy'}/>,
    sortable: true,
    reorder: true
  },
  {
    id: 2,
    name: "Proxy",
    dbColName: "proxy",
    selector: (row) => row.proxy,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "Port",
    dbColName: "port",
    selector: (row) => row.port,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 4,
    name: "Proxy User",
    dbColName: "user",
    selector: (row) => row.user,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 5,
    name: "Proxy Password",
    dbColName: "password",
    selector: (row) => row.password,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 6,
    name: "Upload Date",
    dbColName: "upload_date",
    selector: (row) =>  row.upload_date!=null? dateFormat(row.upload_date,'datetime'): '',
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }, {
    id: 7,
    name: "User",
    dbColName: "user",
    selector: (row) =>  row.user,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }
]



export const terminalColumns = [
  {
    id: 1,
    name: "Id",
    dbColName: "id",
    selector: (row) => row.id,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    format: (row) => <IdButton row={row} selectedPage={'terminal'}/>,
    sortable: true,
    reorder: true
  },
  {
    id: 2,
    name: "Name",
    dbColName: "name",
    selector: (row) => row.name,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "Ip",
    dbColName: "ip",
    selector: (row) => row.ip,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 4,
    name: "Port",
    dbColName: "port",
    selector: (row) => row.port,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 5,
    name: "Username",
    dbColName: "username",
    selector: (row) => row.username,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 6,
    name: "Ram",
    dbColName: "ram",
    selector: (row) => row.ram,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 7,
    name: "Core",
    dbColName: "cour_count",
    selector: (row) => row.core_count,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 8,
    name: "SSD",
    dbColName: "ssd_storage_size",
    selector: (row) => row.ssd_storage_size,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 9,
    name: "Group Email",
    dbColName: "group_email_quota",
    selector: (row) => row.group_email_quota,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 10,
    name: "Upload Date",
    dbColName: "upload_date",
    selector: (row) =>  row.upload_date!=null? dateFormat(row.upload_date,'datetime'): '',
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }, {
    id: 11,
    name: "User",
    dbColName: "user",
    selector: (row) =>  row.user,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }
]

export const targetColumns = [
  {
    id: 1,
    name: "Id",
    dbColName: "id",
    // format: (row) => <button onClick={()=>console.log('clicked')}>click</button>,
    selector: (row) => row.id,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    format: (row) => <IdButton row={row} selectedPage={'user-target'}/>,
    sortable: true,
    reorder: true
  },
  {
    id: 2,
    name: "Terminal",
    dbColName: "terminal_name",
    selector: (row) => row['terminal_name'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "To Name",
    dbColName: "to_name",
    selector: (row) => row.to_name,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 4,
    name: "Email",
    dbColName: "email",
    selector: (row) => row.email,
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }, {
    id: 5,
    name: "Sent",
    dbColName: "sent",
    selector: (row) => row['sent']?'Yes':'No',
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 6,
    name: "1",
    dbColName: "_1",
    selector: (row) => row['_1']==='nan'?'':row['_1'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 7,
    name: "2",
    dbColName: "_2",
    selector: (row) => row['_2']==='nan'?'':row['_2'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    reorder: true
  },
  {
    id: 8,
    name: "3",
    dbColName: "_3",
    selector: (row) => row['_3']==='nan'?'':row['_3'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 9,
    name: "4",
    dbColName: "_4",
    selector: (row) => row['_4']==='nan'?'':row['_4'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 10,
    name: "5",
    dbColName: "_5",
    selector: (row) => row['_5']==='nan'?'':row['_5'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 11,
    name: "6",
    dbColName: "_6",
    selector: (row) => row['_6']==='nan'?'':row['_6'],
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  },
  {
    id: 12,
    name: "Upload Date",
    dbColName: "upload_date",
    selector: (row) =>  row.upload_date!=null? dateFormat(row.upload_date,'datetime'): '',
    style: {textAlign:'center', borderRight: '1px solid rgb(216, 213, 213)'},
    center: true,
    sortable: true,
    right: true,
    reorder: true
  }
]



const fetchData = (selectedPage, setData, setTotalRowCount, offset, limit, order_by, query) => {
  let fetchAPI = new FetchAPI()
  if (selectedPage == 'user') {
    console.log(query)
    fetchAPI.getAllUsers((res) => {
      console.log(res.data)
      setData(res.data.data)
      setTotalRowCount(res.data.total_rows)
    }, (e) => {
      console.log(e.response)
    }, offset, limit, order_by, query)
  }
  else if (selectedPage == 'group-email') {
    fetchAPI.getAllEmails((res) => {
      console.log(res.data)
      setData(res.data.data)
      setTotalRowCount(res.data.total_rows)
    }, (e) => {
      console.log(e.response)
    }, offset, limit, order_by, query)
  }
  else if (selectedPage == 'proxy') {
    fetchAPI.getAllProxies((res) => {
      console.log(res.data)
      setData(res.data.data)
      setTotalRowCount(res.data.total_rows)
    }, (e) => {
      console.log(e.response)
    }, offset, limit, order_by, query)
  }
  else if (selectedPage == 'terminal') {
    fetchAPI.getAllTerminals((res) => {
      console.log(res.data)
      setData(res.data.data)
      setTotalRowCount(res.data.total_rows)
    }, (e) => {
      console.log(e.response)
    }, offset, limit, order_by, query)
  } else if (selectedPage == 'user-target') {
    let targetAPI = new TargetAPI()
    targetAPI.getAllTarget((res) => {
      console.log(res.data)
      setData(res.data.data)
      setTotalRowCount(res.data.total_rows)
    }, (e) => {
      console.log(e.response)
    }, offset, limit, order_by, query)
  }
}

export const setDataService = (selectedPage, setTitle, setColumns, setData, setTotalRowCount, offset, limit, order_by, query) => {
  let title = ''
  let columns = []
  let data = []
  if (selectedPage === 'group-email') {
    title = 'Group Email'
    columns = emailColumns
    data = emailData
  } else if (selectedPage === 'proxy') {
    title = 'Proxy'
    columns = proxyColumns
    data = proxyData
  } else if (selectedPage === 'terminal') {
    title = 'Terminal'
    columns = terminalColumns
    data = terminalData
  } else if (selectedPage === 'user') {
    title = 'User'
    columns = userColumns
    data = userData
  } else if (selectedPage === 'user-target') {
    title = 'Target'
    columns = targetColumns
    // data = userData
  } 

  setTitle(title)
  

  fetchData(selectedPage, setData, setTotalRowCount, offset, limit, order_by, query)
  setColumns(columns)
  // setData(data)
}


