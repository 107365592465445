const validateUsername = (username)=>{
    if(username!==''){
        return true
    }
    return false
}  

const validateEmail = (email)=>{
    let re = /\S+@\S+\.\S+/
    return re.test(email);  
}

const validatePassword = (password)=>{
    if(password!==''){
        return true
    }
    return false
}

const dateFormat = (date, type='date')=>{
    const d = new Date(date)
    if(type==='date'){
        return d.getDate()+'/'+parseInt(d.getMonth()+1)+'/'+d.getFullYear()
    } else if (type==='datetime'){
        return +String(d.getHours()).padStart(2, '0')+':'+String(d.getUTCMinutes()).padStart(2, '0')+' '+d.getDate()+'/'+parseInt(d.getMonth()+1)+'/'+d.getFullYear()
    }
    
}

export {validateEmail, validatePassword, validateUsername, dateFormat}