import React, {useEffect, useState} from 'react'
// import styles from './ConfirmModal.module.css'
import ButtonReact from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({title, msg, modalShow, setModalShow, handleConfirm}) {
    const handleClose = () => {
        setModalShow(false)
    }
    const handleYes = () => {
        handleConfirm()
        setModalShow(false)
    }
    useEffect(()=>{

    },[])
    return (
        
        <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{msg}</div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonReact variant="secondary" onClick={handleClose}>
                    No
                </ButtonReact>
                <ButtonReact variant="primary" onClick={handleYes}>
                    Yes
                </ButtonReact>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal