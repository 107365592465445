import React, { useEffect, useState } from 'react'
import styles from './EditEntryModal.module.css'
import { EditAPI } from '../../api/AdminAPI';
import ButtonReact from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TargetAPI } from '../../api/DbAPI';

function EditEntryModal({ row, selectedPage, modalShow, setModalShow, setDataViewRefresh }) {
    let editAPI = new EditAPI()
    const [data, setData] = useState(row)
    const [responseMsg, setResponseMsg] = useState('')
    const [name, setName] = useState('')
    useEffect(() => {
        // console.log(Object.keys(data))
    }, [data])
    useEffect(() => {
        if (selectedPage === 'user') {
            setName('User')
        } else if (selectedPage === 'group-email') {
            setName('Group Email')
        } else if (selectedPage === 'proxy') {
            setName('Proxy')
        } else if (selectedPage === 'terminal') {
            setName('Terminal')
        } else if (selectedPage === 'user-target') {
            setName('Target')
        }
    }, [selectedPage])
    const onResponse = (res) => {
        console.log(res)
        setResponseMsg(name + ' updated')
        setTimeout(() => {
            setResponseMsg('')
        }, 2000)
        // setDataViewRefresh(s => !s)
    }
    const onError = (e) => {
        console.log(e.response)
        alert(JSON.stringify(e.response.data))
    }

    const handleClose = () => {
        setModalShow(false)
        setResponseMsg('')
    }
    const handleUpdate = () => {
        // handleConfirm()
        // setModalShow(false)
        console.log(data)
        if (selectedPage === 'user') {
            editAPI.editUser(onResponse, onError, data)
        } else if (selectedPage === 'group-email') {
            editAPI.editEmail(onResponse, onError, data)
        } else if (selectedPage === 'proxy') {
            editAPI.editProxy(onResponse, onError, data)
        } else if (selectedPage === 'terminal') {
            editAPI.editTerminal(onResponse, onError, data)
        } else if (selectedPage === 'user-target') {
            let targetAPI = new TargetAPI()
            targetAPI.editTarget(onResponse, onError, data)
        }
    }
    useEffect(() => {

    }, [])
    const onChangeInput = (key, value) => {
        let newData = { ...data }
        newData[key] = value
        setData(newData)
    }
    return (
        <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Edit {name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.form} >
                    {
                        Object.keys(data).map((key, index) => {
                            return (
                                <div key={'keysdata' + index}>
                                    <div className={styles.title} >{key}</div>
                                    {
                                        data[key] === false || data[key] === true
                                            ? <>
                                                <label className={styles.switch}>
                                                    <input className={styles.sliderInput} type="checkbox" checked={data[key]} onChange={(e) => onChangeInput(key, e.target.checked)} />
                                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                                </label></>
                                            : <input className={styles.input}  value={data[key] === 'nan' ? '' : data[key]} onChange={(e) => onChangeInput(key, e.target.value)} />
                                    }

                                    {/* {
                                        data[key] === 'nan'
                                            ? <input value={data[key]==='nan'?'':data[key]} onChange={(e) => onChangeInput(key, e.target.value)} />
                                            : <input value={data[key]} onChange={(e) => onChangeInput(key, e.target.value)} />
                                    } */}
                                </div>
                            )
                        })
                    }
                </div>
                {
                    responseMsg !== ''
                        ? <div className={styles.responseMsg} >{responseMsg}</div>
                        : null
                }
            </Modal.Body>
            <Modal.Footer>
                <ButtonReact variant="secondary" onClick={handleClose}>
                    Close
                </ButtonReact>
                <ButtonReact variant="primary" onClick={handleUpdate}>
                    Update
                </ButtonReact>
            </Modal.Footer>
        </Modal>
    )
}
export default EditEntryModal