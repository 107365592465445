import React, { useEffect, useState } from 'react'
import DataView from '../admin/DataView'
import UploadContent from '../admin/UploadContent'
import styles from './Target.module.css'

function Target() {
    const [refreshDataview, setRefreshDataview] = useState(false)
    useEffect(() => {

    }, [])
    return (
        <div>
            <UploadContent setRefreshDataview={setRefreshDataview} selectedPage={'user-target'} />
            <DataView refresh={refreshDataview} selectedPage={'user-target'} />
        </div>
    )
}
export default Target