import React from 'react'
import styles from './container.module.css'

function Container({ children, style, className }) {
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            {children}
        </div>
    )
}

export default Container