let PRODUCTION = true
var backend_host = ''
if(PRODUCTION){
    backend_host = 'https://api.gmonstercloud.co/'
} else {
    backend_host = 'http://127.0.0.1:8000/'
}
// var backend_host = 'http://159.223.122.212:8000/'
// var backend_host = 'http://gmonstercloud.co:8000/'

export {backend_host}