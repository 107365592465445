import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

function Logout({}){
    let navigate = useNavigate()
    
    useEffect(()=>{
        localStorage.setItem('token',undefined)
        navigate('/login')
    },[])

    return (
        <></>
    )

}
export default Logout