import React from 'react'
import {useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/monster-oldalra.png'
import styles from './HeaderMain.module.css'

function HeaderMain({ name }) {
    let navigate =useNavigate()
    const onClickLogout = (e) => {
        navigate('/logout')
    }  
    return (
        <div className="container-fluid h-100">
            <div className={`row h-100`}>
                <div className={`col-sm-3`} style={{
                    backgroundColor: 'rgb(236, 233, 233)',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}>
                    <div style={{ borderBottom: '1px solid gray', paddingBottom: 10, }}>
                        <img className={styles.logo_img} src={logo}></img>
                    </div>
                </div>
                <div className={`col-sm-9`}>
                    <div style={{ fontSize: 28, marginTop: 20 }}>
                        Dashboard
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <button onClick={onClickLogout} style={{ backgroundColor: 'white', border: '0px solid gray' }}>
                            <FontAwesomeIcon icon={faSignOut} />
                            &nbsp;Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderMain