import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

function AuthCheck({children}) {
    let navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    useEffect(()=>{
        if (localStorage.token===undefined || localStorage.token === 'undefined'){
            navigate('/logout')
        } else {
            setChecked(true)
        }
    },[])
    return (
        <>{checked?children:null}</>
    )
}
export default AuthCheck