import React, { useEffect, useState } from 'react'
import styles from './Notification.module.css'
import { FetchAPI, UpdateAPI } from '../../api/AdminAPI'


let updateAPI = new UpdateAPI()

function Notification({ setRefreshNotification }) {
    let fetchAPI = new FetchAPI()
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [orderBy, setOrderBy] = useState('username')

    // const [notifications, setNotifications] = useState([{
    //     id: 'asda-asdad',
    //     message: 'You added 100 new terminals',
    //     read: false,
    //     time: '22-07-09T11:05.1231Z'
    // }, {
    //     id: 'basda-asdaka',
    //     message: 'You removed user ragib from the database',
    //     read: true,
    //     time: '22-07-10T12:05.1231Z'
    // }])
    const [notifications, setNotifications] = useState([])

    const onResponseFetch = (res) => {
        console.log(res.data)
        setNotifications(res.data)
        setRefreshNotification(res.data.filter(value => value.read==false).length)
    }

    const onErrorFetch = (e) => {
        console.log(e.response)
    }

    useEffect(() => {
        fetchAPI.getAllNotifications(onResponseFetch, onErrorFetch, offset, limit, orderBy)
    }, [])

    return (
        <div className={styles.container} >
            {notifications.map((value, index) =>
                <div key={'notification' + index}>
                    <NotificationCard id={value.id} message={value.message} read={value.read} time={value.time} setRefreshNotification={setRefreshNotification} />
                </div>
            )}
        </div>
    )
}

function NotificationCard({ id, message, read, time, setRefreshNotification }) {
    const [readState, setReadState] = useState(read)
    const [styleSheet, setStyleSheet] = useState({})
    

    useEffect(() => {
        if (read === false) {
            setStyleSheet({ background: 'rgb(223, 220, 220)' })
        } 
    }, [])

    const onClickRead = () => {
        if (readState == false) {
            setReadState(true)
            setStyleSheet({})
            setRefreshNotification(state => state - 1)
        }
        updateAPI.notificationMarkAsRead((res)=>{
            console.log(res.data)
        }, (e)=>{
            console.log(e.response)
        }, id)
        // network call here to set read
    }

    return (
        <div className={styles.notificationBox} style={styleSheet}>
            <div>
                <div className={`${styles.notificationBoxMessage}`} >{message}</div>
                <div className={`${styles.notificationBoxTime}`} >{time}</div>
            </div>

            {
                readState == false
                    ? <button onClick={onClickRead} className={`${styles.notificationBoxRead}`}>mark as read</button>
                    : null
            }

        </div>
    )
}
export default Notification