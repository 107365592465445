import './App.css';
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/home/Home';
import Login from './components/register-and-login/Login';
import Register from './components/register-and-login/Register';
import Footer from './components/header-and-navbar/Footer';
import Header from './components/header-and-navbar/Header';
import Container from './components/container/Container';
import 'bootstrap/dist/css/bootstrap.css'
import TerminalInfo from './components/dashboard/TerminalInfo';
import { Helmet } from "react-helmet";
import icon from '../src/assets/favicon.ico'
import Logout from './components/register-and-login/Logout';
import AdminMain from './components/admin/AdminMain';
import AuthCheck from './components/register-and-login/AuthCheck';
import { useNavigate } from 'react-router-dom'

function App() {
  
  const [verified, setVerified] = useState(false)
  useEffect(() => {
    if (localStorage.token === undefined || localStorage.token === 'undefined') {

    } else {
      setVerified(true)
    }
  }, [])

  return (
    <div className="App">
      <Helmet>
        <title>DASHBOARD | GMonster</title>
        <link rel="icon" type="image/png" href={icon} />
      </Helmet>


      <BrowserRouter>
        {/* <Header /> */}

        <Container>
          <Routes>
            {/* AUTHENTICATION */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />

            {/* ADMIN */}
            <Route path="/admin" element={<AdminMain />} />

            {/* PAGES */}
            <Route path="/" element={<AuthCheck children={<Home />}/>} />
            <Route path="/dashboard" element={<AuthCheck children={<Home />}/>} />
            <Route path="/account" element={<AuthCheck children={<Home />}/>} />
            <Route path="/add-ons" element={<AuthCheck children={<Home />}/>} />
            <Route path="/support" element={<AuthCheck children={<Home />}/>} />
            <Route path="/account-email" element={<AuthCheck children={<Home />}/>}></Route>
            <Route path="/proxy" element={<AuthCheck children={<Home />}/>}></Route>
            <Route path="/target" element={<AuthCheck children={<Home />}/>}></Route>
            {/* <Route path="/terminal/:id" element={<TerminalInfo />}></Route> */}
            <Route path="/terminal/:id" element={<AuthCheck children={<Home />}/>}></Route>
          </Routes>
        </Container>
        <AuthCheck children={<Footer/>}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
