import React from 'react'
import styles from './footer.module.css'
function Footer() {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.entry} ><a href='#'>Affiliation Program</a></div>
                <div className={styles.entry} ><a href='#'>Privacy Policy</a> || <a href='#'>Non-affiliation Disclosure</a> || <a href='#'>Terms {'&'} Conditions</a></div>
                <div className={styles.entry} ><a href='#'>Blog</a></div>
            </div>
            <div style={{display:'flex', alignItems:'flex-end'}}>
                <div className={styles.entry} >Copyright 2020 @<a href='https://gmonster.co/'>Gmonster.co</a></div>
            </div>
        </div>
    )
}

export default Footer