import axios from 'axios'
import { backend_host } from '../config'

class DashboardAPI {
    token = localStorage.getItem('token')
    headers = { headers: { "Authorization": `Token ${this.token}` } }
    createNewTerminal(onResponse, onError) {
        axios.get(backend_host + 'terminal/create-new-terminal/')
            .then(onResponse).catch(onError);
    }
    terminals(onResponse, onError) {
        axios.get(backend_host + 'terminal/',
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
    terminal(onResponse, onError, id) {
        axios.get(backend_host + 'terminal/' + id + '/')
            .then(onResponse).catch(onError);
    }
    terminalNameSave(onResponse, onError, terminal_id, terminal_name) {
        axios.post(backend_host + 'terminal/save-name/', {terminal_id, terminal_name}, this.headers)
            .then(onResponse).catch(onError);
    }
}

class AccountAPI {
    token = localStorage.getItem('token')
    user_id = localStorage.getItem('user_id')
    changePassword(onResponse, onError, old_password, new_password) {
        axios.post(backend_host + `user/change-password/`, {
            old_password: old_password,
            new_password: new_password
        }, { headers: { "Authorization": `Token ${this.token}` } }).then(onResponse).catch(onError);
    }

    userInfo(onResponse, onError) {
        axios.get(backend_host + 'user/info/', { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    getNotification(onResponse, onError) {
        axios.get(backend_host + 'notification/get/', { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    getSubscriptions(onResponse, onError) {
        axios.get(backend_host + 'terminal/get-subscriptions/', { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
}

class AddonAPI {
    token = localStorage.getItem('token')
    user_id = localStorage.getItem('user_id')
    getTerminalList(onResponse, onError) {
        axios.get(backend_host + `terminal/terminal-list/`,
            { headers: { "Authorization": `Token ${this.token}` } }).then(onResponse).catch(onError);
    }

    // buyTerminal(onResponse, onError, terminal_id, amount){
    //     // axios.post(backend_host + `terminal/terminal-list/${this.user_id}/`, {
    //     // }).then(onResponse).catch(onError);
    // }
}

class EmailProxy {
    token = localStorage.getItem('token')
    user_id = localStorage.getItem('user_id')
    getEmails(onResponse, onError, offset, limit, order_by) {
        axios.post(backend_host + `groupemail/user-has-email/`, {offset, limit, order_by},
            { headers: { "Authorization": `Token ${this.token}` } }).then(onResponse).catch(onError);
    }

    reassignEmailToTerminal(onResponse, onError, data) {
        axios.post(backend_host + `groupemail/reassign-terminal/`, data,
            { headers: { "Authorization": `Token ${this.token}` } }
        ).then(onResponse).catch(onError);
    }

    getProxies(onResponse, onError, offset, limit, order_by) {
        axios.post(backend_host + `proxy/user-has-proxy/`, {offset, limit, order_by},
            { headers: { "Authorization": `Token ${this.token}` } }
        ).then(onResponse).catch(onError);
    }

    reassignProxyToTerminal(onResponse, onError, data) {
        axios.post(backend_host + `proxy/reassign-terminal/`, data,
            { headers: { "Authorization": `Token ${this.token}` } }
        ).then(onResponse).catch(onError);
    }

    // buyTerminal(onResponse, onError, terminal_id, amount){
    //     // axios.post(backend_host + `terminal/terminal-list/${this.user_id}/`, {
    //     // }).then(onResponse).catch(onError);
    // }
}
class SupportAPI {
    token = localStorage.getItem('token')
    user_id = localStorage.getItem('user_id')
    submitIssue(onResponse, onError, subject, issue) {
        axios.post(backend_host + `support/ticket/`, {
            subject: subject,
            issue: issue
        }, { headers: { "Authorization": `Token ${this.token}` } }).then(onResponse).catch(onError);
    }
}

class SubscriptionAPI {
    submitIssue(onResponse, onError, subject, issue) {
        axios.get(backend_host + 'subscription/active-subscriptions/')
            .then(onResponse).catch(onError);
    }
}

class TargetAPI {
    token = localStorage.getItem('token')
    headers = { headers: { "Authorization": `Token ${this.token}` } }
    getAllTarget(onResponse, onError, offset, limit, order_by, query) {
        axios.post(backend_host + 'target/get-all-targets/', { offset, limit, order_by, query}, this.headers)
        .then(onResponse).catch(onError)
    }
    uploadTargets(onResponse, onError, data, selected_terminal_id) {
        axios.post(backend_host + 'target/upload-target/', { data, selected_terminal_id }, this.headers)
            .then(onResponse).catch(onError)
    }
    editTarget(onResponse, onError, data) {
        axios.post(backend_host + 'target/edit-target/', data, { ...this.headers })
            .then(onResponse).catch(onError);
    }
    deleteTarget(onResponse, onError, ids) {
        axios.delete(backend_host + 'target/delete-target/', { data: { ids }, ...this.headers },
            this.headers
        ).then(onResponse).catch(onError);
    }
}


export { DashboardAPI, AccountAPI, AddonAPI, EmailProxy, SupportAPI, SubscriptionAPI, TargetAPI }