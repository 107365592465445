import React, { useState, useEffect, useRef } from 'react'
import styles from './Slider.module.css'

function Slider({ sliderValue, setSliderValue, min, max }) {
    const sliderRef = useRef()
    const [width, setWidth] = useState(0)
    let ticks = 5
    // const [ticks, setTicks] = useState(5)
    useEffect(() => {
        // console.log(sliderRef)
        // console.log(sliderRef.current.clientWidth)
        setWidth(sliderRef.current.clientWidth)
        // console.log(new Array(ticks).fill(0))
    }, [])

    return (
        <div ref={sliderRef} className={styles.slidecontainer}>
            <input type="range" min={min} max={max} value={sliderValue} onChange={(e) => {
                setSliderValue(e.target.value)
            }} className={styles.slider} />
            <div style={{paddingRight:12, paddingLeft:12}}>
                {
                    (new Array(ticks).fill(0)).map((value, index) => {
                        return <div className={styles.ticks} style={{ width: (width-25) / ticks }} key={index}>
                            <div className={styles.mark} >╹</div>
                            <div>{(index + 1) * (max - min) / ticks}</div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Slider