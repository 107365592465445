import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import styles from './header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCartShopping, faSignOut } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/monster-oldalra.png'

function Header() {
    const [logout, setLogout] = useState(false)

    const onClickLogout = (e)=>{
        setLogout(true)
        console.log('logging out')
    }

    return (
        <div className={`${styles.container} row`}>
            <div className='col-sm-3 col-md-3 col-lg-3' style={{backgroundColor:'rgb(236, 233, 233)'}}>
                <img className={styles.logo_img} src={logo}></img>
            </div>

            <div className='col-sm-9 col-md-9 col-lg-9'>
                <div style={{ fontSize: 28 }}>
                    Dashboard
                </div>
                <div style={{textAlign: 'right' }}>
                    <button onClick={onClickLogout} style={{backgroundColor:'white', border:'0px solid gray'}}>
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                        <FontAwesomeIcon icon={faSignOut} />
                        &nbsp;Logout
                    </button>
                </div>
            </div>

            {/* <div className={styles.nav_link_container}>
                <Link className={styles.nav_link} to="/">Home</Link>
                <Link className={styles.nav_link} to="/pricing" >Pricing</Link>
                <Link className={styles.nav_link} to="/faq">FAQ</Link>
                <Link className={styles.nav_link} to="/tutorials">Tutorials</Link>
                <Link className={styles.nav_link} to="/contact">Contact</Link>
                <Link className={styles.nav_link} to="/login">Login</Link>
                <Link className={styles.nav_link} to="/cart"><FontAwesomeIcon icon={faCartShopping} /></Link>
            </div> */}

        </div>
    )
}

export default Header