import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../default/Button'
import styles from './Dashboard.module.css'
import windowslogo from '../../assets/windows-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faDesktop } from '@fortawesome/free-solid-svg-icons'
import { DashboardAPI } from '../../api/DbAPI'
import AuthCheck from '../register-and-login/AuthCheck'


function Dashboard() {
  let api = new DashboardAPI()
  const [ipPort, setIpPort] = useState([])
  const [userId, setUserId] = useState(0)
  // const [terminalData, setTerminalData] = useState([{
  //   "id": "asd1-12312",
  //   "ip": "127.0.0.1",
  //   "name": "Terminal 1",
  //   "ram": "2 GB",
  //   "core_count": 1,
  //   "ssd_storage_size": "60 GB",
  //   "is_active": "Running",
  //   "username": "Frank"
  // }])
  const [terminalData, setTerminalData] = useState([])
  useEffect(() => {
    api.terminals((response) => {
      console.log(response.data)
      setTerminalData(response.data)
      // let data = []
      // for(let d in response.data){
      //   data.push(d)
      // }
      setIpPort(response.data)
    }, (e) => {

    })
  }, [])

  const onClickCreateNewTerminal = (e) => {
    console.log('creating new terminal')

    api.createNewTerminal((response) => {

    }, (e) => {

    }, userId)
  }

  return (
    <>
    <AuthCheck/>
      <div className={styles.itemContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <div className={styles.title}>Dashboard</div> */}
          <div></div>
          <Button className={styles.createNewterminalBtn} text={'Create New Terminal'} onClick={onClickCreateNewTerminal}></Button>
        </div>
        <div className='row'>
          {/* {
              ipPort.map((item, index) => {
                <TerminalBox name={'Terminal 1'} logo={windowslogo} info={'2 GB Ram, 1 vCPU 60 GB SSD'} status={'Running'}
                  ip={'127.0.0.1'} machineaddress={'12jhaoiw012enasdaso'} owner={'Frank'} />
              })
            } */}
          {/* <TerminalBox id={'asda'} name={'Terminal 1'} logo={windowslogo} info={'2 GB Ram, 1 vCPU 60 GB SSD'} status={'Running'}
              ip={'127.0.0.1'} machineaddress={'12jhaoiw012enasdaso'} owner={'Frank'} /> */}
          {
            terminalData.map((value, index) =>
              <div key={'terminal'+index} className={`col-12 col-sm-6`}>
                <TerminalBox key={`termianls${index}`} id={value.id} name={value.name} logo={windowslogo} info={`${value.ram} RAM ${value.core_count} core CPU ${value.ssd_storage_size} SSD`} status={value.is_active ? 'Running' : 'Stopped'}
                  ip={value.ip} machineaddress={'12jhaoiw012enasdaso'} owner={value.username} />
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

function TerminalBox({ id, name, logo, info, status, ip, machineaddress, owner }) {

  const onClickButton = (e) => {
    console.log('clicked')

  }

  return (
    <>
      <Link to={'/terminal/' + id}>
        <button className={`${styles.terminalBox}`} onClick={onClickButton}>
          <div style={{ display: 'flex' }}>
            <img className={styles.terminalOsLogo} src={logo} />

            <div style={{ paddingLeft: 10, width: '100%' }}>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={styles.terminalName}>
                  {name}
                </div>
                <div style={{ paddingLeft: 'auto' }}>
                  <FontAwesomeIcon style={{ color: 'red', marginRight: 8 }} icon={faDesktop} />
                  <FontAwesomeIcon style={{ color: 'red' }} icon={faEllipsisV} />
                </div>
              </div>

              <div className={styles.terminalInfo}> {info}</div>
            </div>
          </div>

          <div style={{ border: '1px solid #dedbd5' }}></div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.terminalInfo}>{status}</div>
            <div>
              <div className={styles.terminalInfo} style={{ textAlign: 'right' }}>{ip}</div>
              <div className={styles.terminalInfo} style={{ textAlign: 'right' }}>{machineaddress}</div>
              <div className={styles.terminalInfo} style={{ textAlign: 'right' }}>{owner}</div>
            </div>
          </div>
        </button>
      </Link>
    </>
  )
}

export default Dashboard