import React, { useEffect, useState } from 'react'
import styles from './AdminMain.module.css'
import AdminSidebar from './AdminSidebar'
import logo from "../../assets/monster-oldalra.png";
import DataView from './DataView';
import UploadContent from './UploadContent';
import Notification from './Notification';
import {FetchAPI} from '../../api/AdminAPI'

function AdminMain() {
    let fetchAPI = new FetchAPI()

    const [selectedPage, setSelectedPage] = useState('user')
    const [refreshNotification, setRefreshNotification] = useState()
    const [refreshDataview, setRefreshDataview] = useState(false)
    useEffect(() => {
        fetchAPI.getAllNotifications((res)=>{
            setRefreshNotification(res.data.filter(value => value.read==false).length)
        }, ()=>{}, 0, 1000, 'read')
    }, [])

    return (
        <>
            <AdminNavbar />
            <div className={`row`} style={{ padding: 0, margin: 0 }}>
                <div className={`col-3`} style={{ padding: 0, margin: 0 }}>
                    <AdminSidebar refreshNotification={refreshNotification} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>
                <div className={`col-9`} style={{ padding: 0, margin: 0 }}>
                    {
                        selectedPage === 'terminal' || selectedPage === 'proxy' || selectedPage === 'group-email'
                            ? <>
                                <UploadContent setRefreshDataview={setRefreshDataview} selectedPage={selectedPage} />
                            </>
                            : null
                    }
                    {
                        selectedPage!=='notification'
                        ? <DataView refresh={refreshDataview} selectedPage={selectedPage} />
                        : null
                    }
                    {
                        selectedPage === 'notification'
                        ? <Notification setRefreshNotification={setRefreshNotification}/>
                        : null
                    }
                </div>
            </div>
        </>
    )
}


function AdminNavbar({ }) {

    return (
        <div className={styles.navbarContainer} >
            <div><img className={styles.navbarLogo} src={logo}></img></div>
            <div className={styles.navbarTitle} >ADMIN PANEL</div>
        </div>
    )
}
export default AdminMain