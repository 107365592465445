import React from 'react'

function Inquiry({ title }) {
    return (
        <button title={title} style={{ display: 'inline', marginLeft:5, marginRight:5, 
        padding:'1px 5px', width:18, height:18, fontSize: 10,
    border:'solid 1px gray', borderRadius:9, backgroundColor:'black', color: 'white'}}>?</button>
    )
}

export { Inquiry }