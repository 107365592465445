import React, { useState, useEffect } from 'react'
import Button from '../default/Button'
import styles from './Account.module.css'
import logo from '../../assets/monster-oldalra.png'
import { AccountAPI } from '../../api/DbAPI'
import AuthCheck from '../register-and-login/AuthCheck'
import { dateFormat } from '../../utlis'
import { Link } from 'react-router-dom'

function Account() {
  let api = new AccountAPI()
  const [subs, setSubs] = useState([
    { "name": 'Terminal 1', "terminal_id": "123-assd", "assigned_emails": 100, "assigned_proxies": 20, "start_date": "12-jan-22", "end_date": "12-jan-23" },
    { "name": 'Terminal 2', "terminal_id": "123-ass", "assigned_emails": 50, "assigned_proxies": 10, "start_date": "12-jan-22", "end_date": "12-jan-23" }])

  const [notifications, setNotifications] = useState([{ message: 'Your Bill Paid!', date: '14-jan-22' },
  { message: 'Your order is in progress', date: '12-jun-22' }])

  const [username, setUsername] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')

  useEffect(() => {
    api.userInfo((res) => {
      console.log(res.data)
      setUsername(res.data.username)
    }, (e) => {
      console.log(e.response)
      alert(JSON.stringify(e.response.data))
    })

    api.getNotification((res) => {
      console.log(res.data)
      setNotifications(res.data)
    }, (e) => {
      console.log(e.response)
      alert(JSON.stringify(e.response.data))
    })

    api.getSubscriptions((res) => {
      console.log(res.data)
      setSubs(res.data)
    }, (e) => {
      console.log(e.response)
      alert(JSON.stringify(e.response.data))
    })

  }, [])


  const onClickPasswordChange = (e) => {
    console.log('changing password')
    console.log('old password:', oldPassword, '\tnew password:', newPassword, '\tnew password2:', newPassword2)
    if (newPassword !== newPassword2) {
      alert('Both New Password Must Match')
    } else {
      api.changePassword(res => {
        console.log(res.data)
        alert('Password Changed')
      }, e => {
        console.log(e.response.data)
        alert(JSON.stringify(e.response.data))
      }, oldPassword, newPassword)
    }
  }

  const onClickTerminateAccount = (e) => {
    console.log('terminating account')
  }

  return (
    <>
      <AuthCheck />
      {/* <div className={styles.title}>AccountDetail</div> */}
      <div className={styles.userName} >{username}</div>
      <div className='row'>
        <div className='col-md-6'>
          <div>Change Password</div>
          <input className={styles.accountInput} type='password' placeholder='Old Password' onChange={(e) => {
            setOldPassword(e.target.value)
          }} />
          <br></br>
          <input className={styles.accountInput} type='password' placeholder='New Password' onChange={(e) => {
            setNewPassword(e.target.value)
          }} />
          <br></br>
          <input className={styles.accountInput} type='password' placeholder='New Password Again' onChange={(e) => {
            setNewPassword2(e.target.value)
          }} />
          <br></br>
          <Button text={"Submit"} onClick={onClickPasswordChange}></Button>
          <br></br>
          <Notification notifications={notifications} />
        </div>
        <div className='col-md-6'>
          {/* {
            console.log(window.innerWidth)
          }
          {
            window.innerWidth<768?<div style={{marginTop:'50px', borderBottom: '1px solid gray'}}>hi</div>:null
          } */}
          <div className={styles.rowDivider1}></div>
          <div >Active Subscriptions</div>
          {
            subs.map((value, index) =>
              <div key={`subs+${index}`} className={styles.subElem} >
                <div>
                  <img src={logo} style={{ width: 50 }}></img>
                  <div style={{ display: 'inline', marginLeft: 5, fontSize: 12 }}>
                    <Link style={{color:'black'}} to={`/terminal/${value.terminal_id}`}>{value.name} - {value.assigned_emails} emails - {value.assigned_proxies} proxies</Link>
                  </div>
                  <div style={{ marginLeft: 5, fontSize: 12, fontWeight: 600 }}>
                    start date: {dateFormat(value.start_date)} end date: {dateFormat(value.end_date)}
                  </div>
                </div>
                <button style={{ border: '1px solid rgba(128, 128, 128, 0.288)', marginLeft: 5, fontSize: 12 }}>Terminate</button>
              </div>
            )
          }

          {/* <div style={{textAlign:'right'}}> */}
          {/* <Button text={"Terminate Account"} onClick={onClickTerminateAccount}></Button> */}
          {/* </div> */}
        </div>
      </div>
    </>
  )
}


function Notification({ notifications }) {
  return (<div style={{ boxShadow: '0 3px 5px gray', backgroundColor: 'white', marginTop: 20, padding: '10px 10px', paddingBottom: 30 }}>
    <div style={{ marginTop: 2, marginBottom: 20, fontSize: 18, fontWeight: 600 }}>Notification</div>
    <div>
      {
        notifications.map((value, index) =>
          <div key={`notification${index}`} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px', borderBottom: '1px solid gray' }}>
            <div style={{ fontSize: 14, fontWeight: 600 }}>{value.message}</div>
            <div style={{ fontSize: 12, fontStyle: 'italic' }}>{dateFormat(value.date, 'datetime')}</div>
          </div>
        )
      }
    </div>
  </div>)
}

export default Account
