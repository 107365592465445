import React, {useState} from 'react'
import Button from '../default/Button'
import styles from './panels.module.css'
import { SupportAPI } from '../../api/DbAPI'
function Support() {
    let api = new SupportAPI()
    const [subject, setSubject] = useState('')
    const [issue, setIssule] = useState('')

    const onClickSubmit = (e)=>{
        console.log('subject:',subject,'\tissue:',issue)
        api.submitIssue((res)=>{
            console.log(res)
            alert(res.data.msg)
        }, (e)=>{
            console.log(e.response.data)
            alert(JSON.stringify(e.response.data))
        },subject, issue)
    }

    return (
        <>
            <div>Submit A Ticket</div>
            <input className={`${styles.accountInput} ${styles.supportInput}`} type='text' placeholder='Subject' onChange={(e)=>{
                setSubject(e.target.value)
            }}/>
            <br></br>
            <textarea className={`${styles.accountInput} ${styles.supportInput}`} type='text' placeholder='Please describe your issue' style={{ minHeight: '200px' }}  onChange={(e)=>{
                setIssule(e.target.value)
            }}/>
            <br/>
            <Button text={"Submit"} onClick={onClickSubmit}></Button>
            <div style={{marginTop:50}}>
                <div style={{fontSize:18, fontWeight:600, lineHeight:1.8}}>Alternative Support Channels</div>
                <div style={{borderLeft: '4px solid #dedbd5', fontSize: 14, paddingLeft: 5, marginTop:4}}> Skype: live:cid.bc47e126072e56e0 - skype chat invite</div>
                <div style={{borderLeft: '4px solid #dedbd5', fontSize: 14, paddingLeft: 5, marginTop:4}}> Email: order@gmonster.com</div>
                <div style={{borderLeft: '4px solid #dedbd5', fontSize: 14, paddingLeft: 5, marginTop:4}}> WhatsApp: +1 859 7876882</div>
            </div>
        </>
    )
}

export default Support