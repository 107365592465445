import React, { useState } from "react";
import Button from "../default/Button.js";
import { InputElement } from "./Register.js";
import { validateEmail, validatePassword } from "../../utlis";
import { UserAPI } from "../../api/UserAPI.js";
import FacebookLogin from "react-facebook-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import styles from "./LoginRegister.module.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/monster-oldalra.png";

function Login() {
  let navigate = useNavigate();
  const [regInfo, setRegInfo] = useState({ email: "", password: "" });

  const onEmailChanged = (e) => {
    setRegInfo({ ...regInfo, email: e.target.value });
  };

  const onPasswordChanged = (e) => {
    setRegInfo({ ...regInfo, password: e.target.value });
  };

  const onSubmit = (e) => {
    if (validateEmail(regInfo.email) && validatePassword(regInfo.password)) {
      let userAPI = new UserAPI();
      userAPI.login(
        (response) => {
          console.log(response.data.user_id, response.data.token)
          let user_id = response.data.user_id
          let token = response.data.token
          localStorage.setItem("token", token)
          localStorage.setItem("user_id", user_id)
          toast.success("Login Successful");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        },
        (error) => {
          console.log(error.response.data);
          alert(JSON.stringify(error.response.data));
        },
        regInfo.email,
        regInfo.password
      );
    }
  };

  // onResponse for google and fb auth. Token will be received here.
  const onResponseToken = (res) => {
    console.log(res);
    if ("token" in res.data) {
      let token = res.data.token;
      localStorage.setItem("token", token);
      toast.success("Login Successful");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  };

  const onResponseGoogle = (response) => {
    console.log(response);
    let userAPI = new UserAPI();
    userAPI.register_google(onResponseToken, () => { }, response.credential);
  };

  const responseFacebook = (response) => {
    console.log(response.accessToken);
    let userAPI = new UserAPI();
    userAPI.register_fb(onResponseToken, () => { }, response.accessToken);
  };

  const componentClicked = () => { };

  // const login = useGoogleLogin({
  //     onSuccess: tokenResponse => console.log(tokenResponse),
  //     // flow: 'auth-code',
  // });

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.flex}>
          <img src={logo} className={styles.logo}></img>
          <br />
        </div>
      </div>
      <div className={styles.container}>
        <div className={`${styles.flex} ${styles.inputContainer}`}>
          <InputElement type="email" name="Email" placeholder="Email" onChange={onEmailChanged} className={styles.input} />
          <InputElement type="password" name="Password" placeholder="Password" onChange={onPasswordChanged} className={styles.input} />
          <br />
          <Button onClick={onSubmit} className={styles.submitbtn} text={"Login"} />
          <br />

          <div style={{ textAlign: "center", fontWeight: 700, marginTop: 10 }}>
            OR
          </div>

          <div className={styles.auth_fields}>
            <Link to="/register" className={styles.register_with_email}>
              Register With Email
            </Link>
          </div>

          <div className={styles.auth_fields}>
            <GoogleOAuthProvider clientId="693727332641-1931mctgs5kd3ke53uasnqfj2vqqlm5p.apps.googleusercontent.com">
              {/* <GoogleLoginForm onSuccess={onResponseGoogle} /> */}
              <GoogleLogin
                onSuccess={onResponseGoogle}
                onError={() => {
                  console.log("Login Failed");
                }}
                useOneTap
              />
            </GoogleOAuthProvider>
          </div>

          {/* <div className={styles.auth_fields}>
            <FacebookLogin
              appId="1486240581808822"
              autoLoad={false}
              fields="name,email,picture"
              onClick={componentClicked}
              callback={responseFacebook}
              // cssClass={styles.facebook_button}
              icon="fa-facebook"
            />
          </div> */}
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

function GoogleLoginForm({ onSuccess }) {
  const login = useGoogleLogin({
    onSuccess: onSuccess,
    flow: "auth-code",
  });

  return <button onClick={() => login()}>Sign in with Google 🚀 </button>;
}

export default Login;
