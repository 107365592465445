import axios from 'axios'
import { backend_host } from '../config'

class FetchAPI {
    token = localStorage.getItem('token')
    headers = { headers: { "Authorization": `Token ${this.token}` } }
    getAllUsers(onResponse, onError, offset, limit, order_by, query) {
        axios.post(backend_host + 'myadmin/get-all-users/', { offset, limit, order_by, query },
            this.headers
        ).then(onResponse).catch(onError);
    }
    getAllEmails(onResponse, onError, offset, limit, order_by, query) {
        axios.post(backend_host + 'myadmin/get-all-emails/', { offset, limit, order_by, query },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    getAllProxies(onResponse, onError, offset, limit, order_by, query) {
        axios.post(backend_host + 'myadmin/get-all-proxies/', { offset, limit, order_by, query },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    getAllTerminals(onResponse, onError, offset, limit, order_by, query) {
        axios.post(backend_host + 'myadmin/get-all-terminals/', { offset, limit, order_by, query },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    getAllNotifications(onResponse, onError, offset, limit, order_by) {
        axios.post(backend_host + 'myadmin/get-all-notifications/', { offset, limit, order_by },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
}

class EditAPI {
    token = localStorage.getItem('token')
    headers = { headers: { "Authorization": `Token ${this.token}` } }
    editUser(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/edit-user/', data, { ...this.headers })
            .then(onResponse).catch(onError);
    }
    editEmail(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/edit-email/', data, { ...this.headers })
            .then(onResponse).catch(onError);
    }
    editProxy(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/edit-proxy/', data, { ...this.headers })
            .then(onResponse).catch(onError);
    }
    editTerminal(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/edit-terminal/', data, { ...this.headers })
            .then(onResponse).catch(onError);
    }

}

class DeleteAPI {
    token = localStorage.getItem('token')
    headers = { headers: { "Authorization": `Token ${this.token}` } }
    deleteUser(onResponse, onError, ids) {
        axios.delete(backend_host + 'myadmin/delete-user/', { data: { ids }, ...this.headers },
            this.headers
        ).then(onResponse).catch(onError);
    }
    deleteEmail(onResponse, onError, ids) {
        axios.delete(backend_host + 'myadmin/delete-email/', { data: { ids }, ...this.headers },
            this.headers
        ).then(onResponse).catch(onError);
    }
    deleteProxy(onResponse, onError, ids) {
        axios.delete(backend_host + 'myadmin/delete-proxy/', { data: { ids }, ...this.headers },
            this.headers
        ).then(onResponse).catch(onError);
    }
    deleteTerminal(onResponse, onError, ids) {
        axios.delete(backend_host + 'myadmin/delete-terminal/', { data: { ids }, ...this.headers },
            this.headers
        ).then(onResponse).catch(onError);
    }

}

class UploadAPI {
    token = localStorage.getItem('token')

    uploadEmails(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/upload-emails/', { data },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    uploadProxies(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/upload-proxies/', { data },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }

    uploadTerminals(onResponse, onError, data) {
        axios.post(backend_host + 'myadmin/upload-terminals/', { data },
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
    uploadTaskCheck(onResponse, onError, task_id) {
        axios.get(backend_host + `myadmin/check-task/${task_id}/`,
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
}

class UpdateAPI {
    token = localStorage.getItem('token')

    notificationMarkAsRead(onResponse, onError, notification_id) {
        axios.get(backend_host + `adminnotification/mark-as-read/${notification_id}/`,
            { headers: { "Authorization": `Token ${this.token}` } })
            .then(onResponse).catch(onError);
    }
}


export { FetchAPI, UploadAPI, UpdateAPI, DeleteAPI, EditAPI }