import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './AddOn.module.css'
import logo from '../../assets/monster-oldalra.png'
import Modal from 'react-modal';
import AddOnEmailAccount from './AddOnEmailAccount';
import AddOnProxy from './AddOnProxy';
import AuthCheck from '../register-and-login/AuthCheck';

function AddOn() {
    return (
        <>
            <div className='row'>
                <div className='col-4 col-md-3'>
                    <Card title={'Accounts'} info={'$1/acc'} as={'account'} />
                </div>
                <div className='col-4 col-md-3'>
                    <Card title={'Proxies'} info={'$2.5/mo'} as={'proxy'} />
                </div>
                <div className='col-4 col-md-3'></div>
                <div className='col-4 col-md-3'></div>
                <div className='col-4 col-md-3'></div>
                <div className='col-4 col-md-3'></div>
            </div>
        </>
    )
}

function Card({ title, info, as }) {
    let navigate = useNavigate()
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    const onClick = (e) => {
        setIsOpen(true);
        // if (as === 'account') {

        //     // navigate('/email-account')
        // } else if (as === 'proxy') {
        //     // navigate('/proxy')
        // }
    }


    const customStyles = {
        content: {
            top: 50,
            left: '20%',
            width: '60%',
        },
        overlay: {
            background: "rgba(58, 58, 55, 0.349)"
        }
    };

    return (
        <>
        <AuthCheck/>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >{
                    as === 'account' ?
                        <AddOnEmailAccount />
                        : null
                }
                {
                    as === 'proxy' ?
                        <AddOnProxy/>
                        : null
                }

            </Modal>
            <button style={{ border: '0px', boxShadow: '0 2px 5px gray', padding: '10px 5px', width: '100%' }}
                onClick={onClick}>
                <div style={{ textAlign: 'center' }}>
                    <img src={logo} className={styles.addOnLogo}></img>
                    <div>{title}</div>
                </div>
                <div style={{ textAlign: 'right', fontSize: 12, fontWeight: 100 }}>{info}</div>
            </button>
        </>
    )
}
export default AddOn