import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Sidebar from '../sidebar/Sidebar'
import Dashboard from '../dashboard/Dashboard'
import Account from '../account/Account'
import AddOn from '../addon/AddOn'
import logo from '../../assets/monster-oldalra.png'
import styles from './Home.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCartShopping, faSignOut } from '@fortawesome/free-solid-svg-icons'
import AccountEmail from '../AccountEmail/AccountEmail'
import Proxy from '../Proxy/Proxy'
import Support from '../support/Support'
import AuthCheck from '../register-and-login/AuthCheck'
import Target from '../target/Target'
import TerminalInfo from '../dashboard/TerminalInfo'

function Home() {

  const location = useLocation()
  const { id } = useParams()
  const [selectedSidebar, setSelectedSidebar] = useState('dashboard')
  const [pageName, setPageName] = useState('')
  const [logout, setLogout] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (id !== undefined) {
      setSelectedSidebar('terminal')
    }
  }, [id])

  useEffect(() => {
    // console.log(location.pathname)
    // if(location.pathname==='/'){
    //   navigate('/dashboard')
    //   window.location.reload()
    // }
    if (selectedSidebar === 'dashboard') {
      setPageName('DASHBOARD')
    } else if (selectedSidebar === 'account') {
      setPageName('Account')
    } else if (selectedSidebar === 'add-ons') {
      setPageName('Add-On')
    } else if (selectedSidebar === 'support') {
      setPageName('Support')
    } else if (selectedSidebar === 'account-email') {
      setPageName('Account Email')
    } else if (selectedSidebar === 'proxy') {
      setPageName('Proxy')
    } else if (selectedSidebar === 'target') {
      setPageName('Target')
    }
    // else if (selectedSidebar===''){
    //   setPageName('')
    // } else if (selectedSidebar===''){
    //   setPageName('')
    // } else if (selectedSidebar===''){
    //   setPageName('')
    // } 
  }, [selectedSidebar])


  const sidbarOnClickCallback = (selected) => {
    // console.log(selected)
    setSelectedSidebar(selected)
  }

  const onClickLogout = (e) => {
    setLogout(true)
    console.log('logging out')
    navigate('/logout')
  }

  return (
    <div className="container-fluid h-100" style={{}}>
      <AuthCheck />
      <div className={`row h-100`}>
        <div className={`col-sm-2`} style={{
          backgroundColor: 'rgb(236, 233, 233)',
          paddingLeft: 10,
          paddingRight: 10,
        }}>
          <div style={{ borderBottom: '1px solid gray', height:'100%' }}>
            <div style={{ paddingBottom: 10, }}>
              <img className={styles.logo_img} src={logo}></img>
            </div>
          </div>
        </div>
        <div className={`col-sm-10`} style={{ backgroundColor: 'rgb(236, 233, 233)', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <div className={styles.pageName}>
            {/* {pageName} */}
          </div>
          <div>
            <button className={styles.logoutbtn} onClick={onClickLogout} style={{}}>
              <FontAwesomeIcon icon={faSignOut} />
              &nbsp;Logout
            </button>
          </div>
        </div>
      </div>


      <div className={`row h-100`}>
        <div className={`col-sm-2`} style={{ backgroundColor: 'rgb(236, 233, 233)', padding: '20px 20px' }}>
          <Sidebar onClickCallback={sidbarOnClickCallback} />
        </div>
        <div className={`col-sm-10`} style={{ backgroundColor: '#f7f6f2', minHeight: '100vh', padding: '20px 20px' }}>
          <div style={{}}>
            {
              selectedSidebar === 'dashboard'
                ? <Dashboard />
                : null
            }
            {
              selectedSidebar === 'account'
                ? <Account />
                : null
            }
            {
              selectedSidebar === 'add-ons'
                ? <AddOn />
                : null
            }
            {
              selectedSidebar === 'support'
                ? <Support />
                : null
            }

            {
              selectedSidebar === 'account-email'
                ? <AccountEmail />
                : null
            }
            {
              selectedSidebar === 'proxy'
                ? <Proxy />
                : null
            }
            {
              selectedSidebar === 'target'
                ? <Target />
                : null
            }
            {
              selectedSidebar === 'terminal'
                ? <TerminalInfo />
                : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home