import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './AdminSidebar.module.css'

function AdminSidebar({selectedPage, setSelectedPage, refreshNotification }) {
    const [NotificationNum, setNotificationNum] = useState(2)

    useEffect(() => {
        setNotificationNum(refreshNotification)
    }, [refreshNotification])

    const onClick=(page)=>{
        setSelectedPage(page)
        console.log('selected page:',page)
    }
    return (
        <div className={styles.container} >
            <SidebarButton onClick={(e)=>onClick('user')} selectedPage={selectedPage} label='user' text={'User'}/>
            <SidebarButton onClick={(e)=>onClick('group-email')} selectedPage={selectedPage}  label='group-email' text={'Group Email'}/>
            <SidebarButton onClick={(e)=>onClick('proxy')} selectedPage={selectedPage}  label='proxy' text={'Proxy'}/>
            <SidebarButton onClick={(e)=>onClick('terminal')} selectedPage={selectedPage}  label='terminal' text={'Terminal'}/>
            <SidebarButton onClick={(e)=>onClick('notification')} selectedPage={selectedPage}  label='notification' text={'Notification'}/>
            <div className={styles.notiNumBubble} >{NotificationNum}</div>
        </div>
    )
}

function SidebarButton({ onClick, text, label, selectedPage }) {
    const [styleSheet, setStyleSheet] = useState({})
    useEffect(()=>{
        if(label===selectedPage){
            setStyleSheet({borderBottom: '5px solid white', fontSize:20, transition: '0.1s'})
        } else {
            setStyleSheet({})
        }
        // console.log(label===selectedPage)
    },[selectedPage])

    return (
        <div style={label=='notification'? {display:'inline'}: {}} className={styles.sidebarBtnContainer} >
            <button style={styleSheet} className={styles.sidebarBtn} onClick={onClick}>{text}</button>
        </div>
    )
}
export default AdminSidebar