import React, { useEffect, useState } from 'react'
import styles from './UploadContent.module.css'
import { UploadAPI } from '../../api/AdminAPI'
import { AddonAPI, TargetAPI } from '../../api/DbAPI'
// import Dropdown from 'react-dropdown';
import Dropdown from 'react-bootstrap/Dropdown';

function UploadContent({ selectedPage, setRefreshDataview }) {
    let uploadAPI = new UploadAPI()
    const [selectedTerminal, setSelectedTerminal] = useState({}) // all the terminals of the user
    const [fileSize, setFileSize] = useState(null)
    const [isSelected, setIsSelected] = useState(false)
    const [file, setFile] = useState(null)
    const [isRecieved, setIsRecieved] = useState(false)
    const [taskId, setTaskId] = useState(null)
    const [inputDefaultvalue, setInputDefaultvalue] = useState('')
    useEffect(() => {
        setInputDefaultvalue('')
        setFile(null)
        setFileSize(null)
        setIsRecieved(false)
    }, [selectedPage])
    const onChangeInput = (e) => {
        setIsRecieved(false)
        console.log(e.target.value)
        const files = Array.from(e.target.files)
        setInputDefaultvalue(e.target.value)
        if (e.target.value === '') {
            setIsSelected(false)
            setFileSize(null)
            // setInputDefaultvalue(null)
            return
        }
        console.log("files:", files)
        setIsSelected(true)
        setFileSize(files[0].size)
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            //   console.log(event.target.result)
            setFile(event.target.result)
        })
        reader.readAsDataURL(files[0]);
    }

    const onResponseUpload = (res) => {
        console.log(res.data)
        if (res.data.msg == 'received') {
            setIsRecieved(true)
            setTaskId(res.data.task_id)
        }
    }

    const onErrorUpload = (e) => {
        console.log(e.response)
    }
    const onClickUpload = () => {
        setIsRecieved(false)
        if (file == null) {
            return
        }
        if (selectedPage === 'group-email') {
            uploadAPI.uploadEmails(onResponseUpload, onErrorUpload, file)
        }
        else if (selectedPage === 'proxy') {
            uploadAPI.uploadProxies(onResponseUpload, onErrorUpload, file)
        }
        else if (selectedPage === 'terminal') {
            uploadAPI.uploadTerminals(onResponseUpload, onErrorUpload, file)
        }
        else if (selectedPage === 'user-target') {
            let targetAPI = new TargetAPI()
            targetAPI.uploadTargets(onResponseUpload, onErrorUpload, file, selectedTerminal.terminal_id)
        }
    }
    return (
        <div className={styles.container} >
            <div style={{ marginBottom: 5 }}>Upload csv file for {selectedPage==='user-target'?'Target':selectedPage}</div>
            {
                selectedPage === 'user-target'
                    ? <UserTarget selectedPage={selectedPage} setSelectedTerminal={setSelectedTerminal} selectedTerminal={selectedTerminal} />
                    : null
            }
            <input accept='.csv,.xlsx' onChange={onChangeInput} value={inputDefaultvalue} type={'file'} />

            {
                fileSize != null
                    ? <div style={{ display: 'inline' }}>size: {(fileSize / 1024).toFixed(2)} Kb</div>
                    : null
            }

            {
                isSelected
                    ? <button onClick={onClickUpload} className={styles.uploadBtn} >Upload</button>
                    : null
            }
            {
                isRecieved
                    ? <RecieveSaveCheck setRefreshDataview={setRefreshDataview} taskId={taskId} />
                    : null
            }
            
        </div>
    )
}

function UserTarget({ selectedPage, selectedTerminal, setSelectedTerminal }) {
    const [terminalList, setTerminalList] = useState([]) // all the terminals of the user
    let addonAPI = new AddonAPI()
    useEffect(() => {
        addonAPI.getTerminalList((res) => {
            console.log(res.data)
            setTerminalList(res.data)
            setSelectedTerminal(res.data[0])
        }, (e) => {
            console.log(e.response)
            alert(JSON.stringify(e.response.data))
        })
    }, [])

    return (
        <div style={{paddingTop:8, paddingBottom:8}}>
            <div style={{display:'inline', marginRight:10}}>Select A Terminal</div>
            <Dropdown style={{display:'inline'}}>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {selectedTerminal.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        terminalList.map((value, index) => {
                            return (
                                <Dropdown.Item href="#" key={'terminalList' + index} onClick={(e)=>setSelectedTerminal(value)}>
                                    {value.name}
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

function RecieveSaveCheck({ setRefreshDataview, taskId }) {
    let uploadAPI = new UploadAPI()
    const [text, setText] = useState('Updated file is being processed')
    let interval = null

    const repeatCheck = () => {
        // console.log('repeating')
        uploadAPI.uploadTaskCheck((res) => {
            console.log(res.data)
            if (res.data.msg === "PENDING") {
                let txt = `Task Is Pending: Make sure background task is running.`
                setText(txt)
                if (interval !== null) { clearInterval(interval) }
            } else if (res.data.msg === "Failure") {
                let txt = `Task Failued: Could not save for some error`
                setText(txt)
                if (interval !== null) { clearInterval(interval) }
            }
            else if (res.data.result.task_msg === "some error occurred") {
                let txt = `Some Error Occoured:
                ${JSON.stringify(res.data.result.errors)}`
                setText(txt)
                if (interval !== null) { clearInterval(interval) }
            }
            else if (res.data.result.task_msg === "saved successfully") {
                setText('Saved Successfully')
                if (interval !== null) { clearInterval(interval) }
                setRefreshDataview(e => !e)
            }
        }, (e) => {
            console.log(e.response)
            alert(JSON.stringify(e.response.data))
            if (interval !== null) { clearInterval(interval) }
        }, taskId)
    }
    useEffect(() => {
        interval = setInterval(repeatCheck, 3000)
    }, [])

    return (
        <div>
            {text}
        </div>
    )
}

export default UploadContent